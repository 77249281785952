import React from 'react';
import moment from "moment-timezone";
import {
    BooleanInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    useListFilterContext,
} from 'react-admin';
import BaseListActions from "../components/BaseListActions";
import OperationTypeLookup from "../components/OperationTypeLookup";
import {withTransaction} from "@elastic/apm-rum-react";


const OperationStatByPartyFilter = props => {


    return (

        <Filter {...props} >
            <DateInput alwaysOn source="moment_gte" label="Дата c:" style={{width: "170px"}}/>
            <DateInput alwaysOn source="moment_lte" label="Дата по:" style={{width: "170px"}}/>

            <SelectInput label="Мелкочек" source="party.billingEnabled"
                         choices={[
                             {id: 'true', name: 'Да'},
                             {id: 'false', name: 'Нет'},
                         ]}
                         style={{minWidth: "120px", width: "120px"}}
                         alwaysOn translateChoice={false}
                         InputLabelProps={{shrink: true}}
            />
            <OperationTypeLookup alwaysOn/>

            <TextInput label="Контрагент" source="party.name_like" alwaysOn
                       style={{minWidth: "140px", width: "140px"}}
            />
            <BooleanInput label="По датам" source="groupByDate" alwaysOn/>
        </Filter>
    )
}

const MyDatagrid = props => {
    const {filterValues} = useListFilterContext(props);
    const showDate = filterValues.groupByDate;
    return (
        <Datagrid bulkActionButtons={false}>
            {showDate && <DateField source="moment" label="Дата"/>}
            <TextField source="party.name" label="Контрагент"/>
            <NumberField source="total" label="Всего"/>
            <NumberField source="success" label="Успешно"/>
            <NumberField source="error" label="Ошибок"/>
            <NumberField source="notFound" label="Не найдeно"/>
        </Datagrid>
    )
}

const OperationStatByPartyList = props => {
    let d = new Date()
    const startDate = (d.getDate() == 1) ?
        moment(d).subtract(1, 'months').startOf('month').format("YYYY-MM-DD") :
        moment(d).startOf('month').format("YYYY-MM-DD");
    const stopDate = moment(d).format("YYYY-MM-DD");

    return (
        <List {...props} hasShow={false} hasList={true} hasEdit={false} hasCreate={false}
              title="Сводная статистика по контрагентам за период"
              filters={<OperationStatByPartyFilter/>}
              actions={<BaseListActions name="operationStatbyParty" exportFilename="operationStatByParty.xlsx"/>}
              bulkActionButtons={false}
              perPage={50}
              sort={{field: 'moment', order: 'DESC'}}
              filterDefaultValues={{moment_gte: startDate, moment_lte: stopDate, groupByDate: true}}
        >

            <MyDatagrid/>

        </List>
    )
}

export default withTransaction('OperationStatByPartyList','component')(OperationStatByPartyList);