import React from 'react';
import {AppBar} from 'react-admin';
import Typography from '@mui/material/Typography';
import {withStyles} from '@mui/styles';
import LoadingPopup from "../components/LoadingPopup";

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const CustomAppBar = ({ classes, ...props }) => (
    <AppBar color="primary" {...props}>
        <Typography
            variant="caption"
            color="inherit"
            className={classes.title}
            id="react-admin-title">
          IDX Admin&nbsp;
        </Typography>

        <span className={classes.spacer} />
        <LoadingPopup/>
    </AppBar>
);

export default withStyles(styles)(CustomAppBar);
