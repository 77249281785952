import {DateInput, useListContext} from "react-admin";
import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {formatDate, formatMoney} from "../lib/utils";
import {TableCell, TableFooter, TableRow} from "@mui/material";


export const formatMonth = period => {
    return (
        <span>
        {
            period ?
                new Date(period).toLocaleString("Ru-ru", {month: "short", year: "numeric"}) :
                // formatDate(new Date(), "MMMM YYYY")
                new Date().toLocaleString("Ru-ru", {month: "short", year: "numeric"})
        }
        </span>

    )
}

export const PartyStatMonthlyFilter = props => {
    const {onFilterChange} = props;
    const {filterValues, setFilters} = useListContext();
    const [formValues, setFormValues] = useState(filterValues);

    useEffect(() => {
        setFilters(formValues, undefined, false);
        if (onFilterChange) {
            onFilterChange(formValues);
        }
    }, [formValues]);

    const handleChange = (event) => {
        setFormValues(val => ({
            ...val,
            [event.target.name]: event.target.value
        }));
    };

    const form = useForm({
        defaultValues: filterValues,
    });

    return (
        <FormProvider {...form}>
            <form style={{paddingTop: 20}}>
                <DateInput alwaysOn source="period" label="Месяц" style={{width: "170px"}}
                           parse={p => {
                               return formatDate(new Date(p), 'YYYY.MM') + ".01";
                           }}
                           onChange={handleChange}
                />
            </form>
        </FormProvider>
    )
}


export const PartyStatFooter = props => {
    const {colSpan, totalColumn} = props;
    const {data, isLoading} = useListContext();
    if (isLoading) return null;
    const grandTotal = data && data.map && data
        .map(item => item[totalColumn])
        .reduce((a, b) => a + b, 0);
    return (
        <TableFooter>
            <TableRow>
                <TableCell style={{textAlign: "right", fontSize: "medium"}} colSpan={colSpan}>
                    {formatMoney(grandTotal)}
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}