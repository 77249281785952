import React from "react";
import {AutocompleteInput, ReferenceInput, useGetList, useRecordContext,} from 'react-admin';


const OptionRenderer = props => {
    const record = useRecordContext();
    return (
        <>
        <span style={{width: 100}}>
            {record.id}
        </span>
            <span style={{width: 200, align: "left"}}>{record.name}</span>
            <span style={{width: 300, align: "left"}}>{record.portalName}</span>
        </>
        // `${choice.id} | ${choice.name} | ${choice.portalName}`;
    )
}
const optionText = <OptionRenderer/>;
const inputText = choice => `${choice.id} - ${choice.name}`
const matchSuggestion = (filter, choice) => {
    console.log("filter", filter);

        return (
        (choice.id + " - " + choice.name).toLowerCase().includes(filter.toLowerCase()) ||
        // choice.name.toLowerCase().includes(filter.toLowerCase()) ||
        (choice.portalName && choice.portalName.toLowerCase().includes(filter.toLowerCase()))
        )

}
const OperationTypeLookup = (props) => {
    // const _source = props.source ? props.source : "operationType.id";
    return (
        <ReferenceInput  {...props}
                         source={props.source ? props.source : "operationType.id"}
                         reference="operationType"
                         perPage={1000} sort={{field: "name", order: "ASC"}}
        >
            <AutocompleteInput label="Тип операции" optionText="name" optionValue="id"
                               debounce={10} variant="outlined"
                               translateChoice={false}
                               filterToQuery={searchText => ({name_like: searchText})}
                               sx={{
                                   minWidth: "280px", '& .muiAutocomplete-popper': {
                                       width:'400px',
                                       backgroundColor: '#1c2128'
                                   }
                               }} size="small"
            />
        </ReferenceInput>
    )
}

export const OperationTypeLookupEx = (props) => {
    const {data, isLoading} = useGetList('operationType', {
        pagination: {page: 1, perPage: 1000},
        sort: {field: 'name', order: 'ASC'}
    });
    return (
        <AutocompleteInput
            // isLoading={isLoading}
            source={props.source ? props.source : "operationType.id"}
            label="Тип операции"
            choices={data}
            inputText={inputText}
            optionText={optionText}
            optionValue="id"
            matchSuggestion={matchSuggestion}
            variant="outlined"
            translateChoice={false}
            debounce={1000}
            sx={{
                minWidth: "280px",
            }}
            size="small"
            componentsProps={{
                paper: {
                    sx: {
                        width: props.popupWidth ? props.popupWidth : 'related',
                    }
                }
            }}
        />

    )
}


export default OperationTypeLookup;
