import React from 'react';
import {getBasePath, jsonToParams} from "../lib/utils";
import {Button, useListContext, useNotify,} from 'react-admin';
import {useMutation} from 'react-query';

const ExportExcelButton = ({
                               resourceName,
                               exportFilename,
                               // currentSort,
                               className,
                               ...props
                           }) => {

    const {filterValues, filter, sort} = useListContext();
    const notify = useNotify();
    const mutation = useMutation(async () => {
        await exportFunc()
    })

    const exportFunc = async () => {
        // console.log("export filterValues, filter", filterValues, filter);
        const params = {
            filter: {...filterValues, ...filter, ...props.filterValues},
            range: [0, 0],
            sort: sort ? [sort.field, sort.order] : "",
            excel: true
        }
        const url = getBasePath() + "/" + resourceName + "?" + jsonToParams(params);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json",
                    "Accept": "*/*"
                }),
            });
            const blob = await response.blob();
            if (!response.ok) {
                const text = await blob.text();
                notify(`HTTP ${response.status} - ${response.statusText} : ${text}`);
                return;
            }

            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = exportFilename;
            document.body.appendChild(a);
            a.click();
            a.remove();

        } catch (err) {
            notify(err);
        }
    }

    return (
        <Button {...props} color="primary" onClick={() => {
            mutation.mutate()
        }} label="Экспорт Excel" variant="outlined">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor"
                      d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"/>
            </svg>
        </Button>
    )
}


export default ExportExcelButton;