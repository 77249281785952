import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'react-recompose/pure';
import FalseIcon from '@mui/icons-material/Clear';
import TrueIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import {createStyles, withStyles} from '@mui/styles';
import compose from 'react-recompose/compose';
import {sanitizeFieldRestProps} from "ra-ui-materialui";
import {useRecordContext, useTranslate} from "react-admin";

//import sanitizeRestProps from 'ra-ui-materialui/lib/field/sanitizeRestProps';

const styles = createStyles({
    label: {
        // Move the text out of the flow of the container.
        position: 'absolute',

        // Reduce its height and width to just one pixel.
        height: 1,
        width: 1,

        // Hide any overflowing elements or text.
        overflow: 'hidden',

        // Clip the box to zero pixels.
        clip: 'rect(0, 0, 0, 0)',

        // Text won't wrap to a second line.
        whiteSpace: 'nowrap',
    },
});

export const BooleanField = ({
    className,
    classes,
    source,
    valueLabelTrue,
    valueLabelFalse,
    ...rest
}) => {
    const record = useRecordContext();
    const val = get(record, source);
    const value = val ? true : false;

    let ariaLabel = value ? valueLabelTrue : valueLabelFalse;
    const translate = useTranslate();

    if (!ariaLabel) {
        ariaLabel =
            value === false
                ? translate('ra.boolean.false')
                : translate('ra.boolean.true');
    }

    if (value === false) {
        return (
            <Typography
                component="span"
                body1="body1"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                <span className={classes.label}>{ariaLabel}</span>
                <FalseIcon />
            </Typography>
        );
    }

    if (value === true) {
        return (
            <Typography
                component="span"
                body1="body1"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                <span className={classes.label}>{ariaLabel}</span>
                <TrueIcon />
            </Typography>
        );
    }

    return (
        <Typography
            component="span"
            body1="body1"
            className={className}
            {...sanitizeFieldRestProps(rest)}
        />
    );
};

BooleanField.propTypes = {
    // addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
    valueLabelTrue: PropTypes.string,
    valueLabelFalse: PropTypes.string,
};

BooleanField.defaultProps = {
    classes: {},

};

const PureBooleanField = compose(
    pure,
    withStyles(styles)
)(BooleanField);

PureBooleanField.defaultProps = {
    //addLabel: true,
};

export default PureBooleanField;