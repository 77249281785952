import merge from "lodash/merge";
import {defaultTheme} from "react-admin";
import createPalette from '@mui/material/styles/createPalette';

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        // primary: {
//            main: "#ff0266", // Not far from red
//         },
//         secondary: {
//            main: "#00ba00", // Not far from green
//         },
    })
);

// const spacing = createSpacing(
//     merge({}, {}, {
//         // primary: {
// //            main: "#ff0266", // Not far from red
// //         },
// //         secondary: {
// //            main: "#00ba00", // Not far from green
// //         },
//     })
// );


const theme = {
    ...defaultTheme,
    typography: {
        fontSize: 11,
        caption: {
            fontSize: 16,
        }
    },
    palette: {
        type: 'light', // Switching the dark mode on is a single property value change.
    },
    sidebar: {
        width: 180, // The default value is 240
        closedWidth: 30, // The default value is 55
    },
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        RaLabeled: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            }
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        RaTextInput: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                    fontSize: 16,
                }
            },
        },
        RaSidebar: {
            drawerPaper: {
                styleOverrides: {
                    backgroundColor: palette.common.white,
                    color: palette.primary.main,
                    height: "100%",
                    boxShadow:
                        "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
                }
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    '&.RaMenuItemLink-active': {
                        borderRight: '4px solid #90caf9',
                        backgroundColor: palette.action.selected, // Defined in the default palette
                        color: palette.primary.main,
                        fontWeight: "bold"
                    },
                },
            },
            icon: {
                color: "inherit",
            },
        },

    }
};

// const theme = createTheme(
//     merge(defaultTheme,
//         {
//             typography: {
//                 fontSize: 11,
//                 caption: {
//                     fontSize: 16,
//                 }
//             },
//             palette: {
//                 type: 'light', // Switching the dark mode on is a single property value change.
//             },
//             sidebar: {
//                 width: 180, // The default value is 240
//                 closedWidth: 30, // The default value is 55
//             },
//             overrides: {
//                 RaSidebar: {
//                     drawerPaper: {
//                         backgroundColor: palette.common.white,
//                         color: palette.primary.main,
//                         height: "100%",
//                         boxShadow:
//                             "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
//                     },
//                 },
//                 RaMenuItemLink: {
//                     active: {
//                         borderLeftStyle: "none",
//                         borderRightColor: palette.secondary.main,
//                         borderRightWidth: spacing(2),
//                         borderRightStyle: "solid",
//                         borderRight: "solid",
//                         backgroundColor: palette.action.selected, // Defined in the default palette
//                         color: palette.primary.main,
//                         fontWeight: "bold"
//                     },
//                     icon: {
//                         color: "inherit",
//                     },
//                 }
//             }
//         }
//     ));

export default theme;