import {useGetList, useNotify, usePermissions} from "react-admin";
import React, {useState} from "react";
import PropTypes from "prop-types";
import OperationTypePriceEdit from "./OperationTypePriceEdit";
import roles from "../constants/roles";
import AddIcon from "@mui/icons-material/Add";


export const OperationTypePriceEditDefault = props => {
    const {serviceSpecId, needRefresh} = props;
    const notify = useNotify();
    const {permissions} = usePermissions();


    const {data, isLoading, error} = useGetList("priceList", {
        filter: {serviceSpecId: serviceSpecId, isDefault: true},
    });
    if (isLoading) return;
    if (error) {
        notify("Ошибка при загрузке прайслиста по умолчанию: " + error, {type: 'error', autoHideDuration:10000});
        return;
    }
    // console.log("data", data);
    const priceListId = data[0].id;
    return (
        <>

            <OperationTypePriceEdit {...props} priceListId={priceListId} needRefresh = {needRefresh}/>
        </>
    )
}

OperationTypePriceEditDefault.propTypes = {
    serviceSpecId: PropTypes.number.isRequired,
    needRefresh: PropTypes.number.isRequired
}
