import React from 'react';
import {BooleanField, CreateButton, Datagrid, DateField, DeleteButton, List, TextField} from "react-admin";
import {withTransaction} from "@elastic/apm-rum-react";


const MelkoPromocodeList = props => {
    return (
        <List empty={false} perPage={50}
              actions={<><CreateButton label="Добавить промокод"  /></>}
              title=" Список промокодов">

            <Datagrid bulkActionButtons={false} rowClick="edit" >

                <TextField source="id"/>
                <TextField source="tenant" label="Тарифная группа"/>
                <TextField source="promocodeType" label="Тип"/>
                <TextField source="code" label="Промокод"/>
                <TextField source="name" label="Описание"/>
                <DateField source="startMoment" label="Дата начала действия промокода"/>
                <DateField source="stopMoment" label="Дата окончания действия промокода"/>

                <BooleanField source="isOnetime" label="Однократный"/>

                <DeleteButton label="" mutationMode="pessimistic" />
                {/*<EditButton label="Изменить"/>*/}
            </Datagrid>
        </List>
    )
};


export default withTransaction('melko_promocode','component')(MelkoPromocodeList);