import React, {useEffect} from 'react';
import {useNotify, usePermissions} from "react-admin";
import {useImmer} from "use-immer";
import dataProvider from "../components/data-provider";
import Paper from "@mui/material/Paper";
import {Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from "@mui/material";
import MyEditableLabel from "../components/MyEditableLabel";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import roles from "../constants/roles";

const styles = theme => ({
    container: {minWidth: '600px', marginLeft: '1em'},
    boldCell: {fontWeight: 'bold'},
    tightCell: {width: '70px'},
    centerCell: {textAlign: 'center'},
    head: {
        backgroundColor: "#ededed",
        color: theme.palette.common.white,
        '& th': {
            padding: '4px',
        }
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.default,
        },
        '& td': {
            padding: '4px',
        }
    },

    button: {
        marginTop: 7,
        marginLeft: 14,
        marginBottom: 10
    }
});

const OperationTypePriceEdit = (props) => {
    // console.log('OperationTypePriceEdit props=', props);
    const {priceListId, classes} = props;
    const notify = useNotify();
    const {permissions} = usePermissions();
    const [records, setRecords] = useImmer(null);

    const fetchData = () => {
        console.log("OperationTypePriceEdit.fetchData");
        dataProvider.getList('operationTypePriceList/' + priceListId, {
            // filter: {priceListId: priceListId},
            pagination: {page: 1, perPage: 500},
            sort: {field: 'id', order: 'ASC'}
        })
            .then(response => {
                setRecords(response && response.data);
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка запроса operationTypePriceList: ${e}`, 'warning');
            });
    }

    useEffect(() => {
        fetchData();
    }, [props.needRefresh]);


    const handlePriceEdit = (ref, recNum, typ, val) => {
        //сначала проверяется правильность ввода (цифры) и сравнение со старым значением
        if (isNaN(val) || val === '' || val == null || val <= 0) {
            ref.setState({text: records[recNum].price});
            return;
        }

        // отправка на сервер
        val = val * 100;

        dataProvider
            .update('operationTypePrice', {
                id: records[recNum].id,
                data: {price: val}
            })
            .then(response => {
                setRecords(draft => {
                    draft[recNum].price = val;
                });
                notify('Значение успешно изменено', {autoHideDuration: 2000});
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка при редактировании: ${e}`, {type: 'error'});
            });
    }

    // if (!records) {
    //     fetchData();
    // }

    if (!records) return null;
    return (

        <>

            <Paper className={classes.container}>
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.head} key="header">
                            <TableCell component="th" style={{maxWidth: '50px', textAlign: 'center'}}>ID
                            </TableCell>
                            <TableCell component="th">Операция</TableCell>
                            <TableCell component="th">Источник</TableCell>
                            <TableCell component="th" style={{maxWidth: '250px'}}>Описание</TableCell>
                            <TableCell component="th" style={{maxWidth: '250px'}}>Описание для портала</TableCell>

                            <TableCell align="right">Цена,руб.</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {records && records.length > 0
                            ?
                            records.map(
                                (item, num) =>
                                    <TableRow className={classes.row} key={item.id}>

                                        <TableCell className={classes.centerCell}>
                                            {item.operationTypeDb.id}
                                        </TableCell>

                                        <TableCell sx={{width: '200px'}}>
                                            {item.operationTypeDb.name}
                                        </TableCell>

                                        <TableCell sx={{width: '150px'}}>
                                            {item.operationTypeDb.comment}
                                        </TableCell>

                                        <TableCell sx={{width: '300px'}}>
                                            {item.operationTypeDb.displayName}
                                        </TableCell>
                                        <TableCell sx={{width: '300px'}}>
                                            {item.operationTypeDb.portalName}
                                        </TableCell>

                                        <TableCell align="right" sx={{width: '100px', fontWeight: 'bold'}}>
                                            {permissions.includes(roles.melkocheck_admin) ?
                                                <MyEditableLabel
                                                    text={(item.price / 100.0).toString()}
                                                    inputWidth='50px'
                                                    inputHeight='20px'
                                                    inputMaxLength={6}
                                                    onFocus={(ref, val) => {
                                                        if (val === '-') ref.state.text = '';
                                                    }}
                                                    onFocusOut={(ref, val) => {
                                                        handlePriceEdit(ref, num, 'current', val)
                                                    }}
                                                />
                                                :
                                                (item.price / 100.0).toString()

                                            }

                                        </TableCell>
                                        <TableCell/>
                                    </TableRow>
                            )
                            :
                            <TableRow key={'no-data'}>
                                <TableCell>Что-то пошло не так </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell align="right" colSpan={6}>
                                Всего записей: {records.length}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        </>
    )
}
OperationTypePriceEdit.propTypes = {
    priceListId: PropTypes.number,
}



export default withStyles(styles)(OperationTypePriceEdit);