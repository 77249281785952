import React from 'react';
import moment from "moment-timezone";
import {
    BooleanInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    useListFilterContext,
} from 'react-admin';
import BaseListActions from "../components/BaseListActions";
import OperationTypeLookup from "../components/OperationTypeLookup";
import {withTransaction} from "@elastic/apm-rum-react";

const OperationStatByTypeFilter = props => {
    const {showPartyFilter} = props;
    // console.log("showPartyFilter", showPartyFilter);
    return (
        <Filter {...props} >

            <DateInput alwaysOn source="moment_gte" label="Дата c:" style={{width: "170px"}}/>
            <DateInput alwaysOn source="moment_lte" label="Дата по:" style={{width: "170px"}}/>

            {showPartyFilter &&
                <SelectInput label="Мелкочек" source="party.billingEnabled"
                             choices={[
                                 {id: 'true', name: 'Да'},
                                 {id: 'false', name: 'Нет'},
                             ]}
                             style={{minWidth: "120px", width: "120px", marginBottom: 0}}
                             alwaysOn translateChoice={false}
                    /*InputLabelProps={{shrink: true}}*/
                             variant="outlined"
                />
            }

            <OperationTypeLookup alwaysOn/>

            {showPartyFilter &&
                <TextInput label="Контрагент" source="party.name_like" alwaysOn
                           style={{minWidth: "140px", width: "140px"}}
                />
            }

            <BooleanInput label="По датам" source="groupByDate" alwaysOn/>
        </Filter>
    )
}

const MyDatagrid = props => {
    const {filterValues} = useListFilterContext(props);
    // console.log(props);

    const showDate = filterValues.groupByDate;
    return (
        <Datagrid bulkActionButtons={false}>
            {showDate && <DateField source="moment" label="Дата"/>}
            <TextField source="operationType.name" label="Операция"/>
            <TextField source="operationType.comment" label="Источник"/>
            <TextField source="operationType.displayName" label="Описание"/>
            <NumberField source="total" label="Всего"/>
            <NumberField source="success" label="Успешно"/>
            <NumberField source="error" label="Ошибок"/>
            <NumberField source="notFound" label="Не найдeно"/>
        </Datagrid>
    )
}

const OperationStatByTypeList = props => {
    let d = new Date()

    const {partyId} = props;
    const showInTab  = (partyId != undefined);
    // console.log("partyId=", partyId,"showPartyFilter=",showPartyFilter);

    let storeKey="standard";


    const startDate = (d.getDate() == 1) ?
        moment(d).subtract(1, 'months').startOf('month').format("YYYY-MM-DD") :
        moment(d).startOf('month').format("YYYY-MM-DD");
    const stopDate = moment(d).format("YYYY-MM-DD");

    return (
        <List hasShow={false} hasList={true} hasEdit={false} hasCreate={false}
              title={showInTab? " ": "Сводная статистика по операциям за период"}
              resource="operationStatbyType"
              filter={{"party.id": partyId}}
              storeKey={storeKey}
              filters={<OperationStatByTypeFilter showPartyFilter={!showInTab}/>}
              actions={<BaseListActions name="operationStatbyType" exportFilename="operationStatByType.xlsx"/>}
              bulkActionButtons={false}
              perPage={50}
              sort={{field: 'moment', order: 'DESC'}}
              filterDefaultValues={{moment_gte: startDate, moment_lte: stopDate, groupByDate: true}}
              disableSyncWithLocation={showInTab}
        >

            <MyDatagrid/>
        </List>
    )
}

export default withTransaction('OperationStatByTypeList', 'component')(OperationStatByTypeList);