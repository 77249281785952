import React from 'react';
import {
    DeleteButton,
    Edit,
    ListButton,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar
} from 'react-admin';
import {withStyles} from '@mui/styles';

const TrustPersonBaseTitle = ({base, record}) => {
    return <span>Скоринг благонадежности. Солярис: {record ? `"${record.id}"` : ''}</span>;
};
//
// const useStyles = makeStyles({
//     toolbar: {
//         display: 'flex',
//         justifyContent: 'space-between',
//     },
// });

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    listButton: {
        marginLeft: "30px",
    },
    deleteButton: {
        marginRight: "20px",
        marginLeft: "auto",
    }

};

/*
const CustomToolbar = withStyles(toolbarStyles)(props => {
    //const handleCancelClick = () => reset(REDUX_FORM_NAME);
    const {basePath} = props;
    return (
        <Toolbar {...props}  >
            <SaveButton/>
            <ListButton basePath={basePath} label="Отмена" icon={null}/>
        </Toolbar>
    )
});
*/

const CustomToolbar = withStyles(toolbarStyles)(props => {
    const {basePath, data, resource, classes} = props;
    return (
        <Toolbar {...props} >
            <SaveButton submitOnEnter={false}/>
            <ListButton basePath={basePath} label="Отмена"  className={classes.listButton}/>
            <DeleteButton basePath={basePath} resource={resource} record={data}
                          undoable={false}
                          label="Удалить"
                          confirmTitle="Удаление"
                          confirmContent="Вы уверены, что хотите удалить эту запись?"
                          submitOnEnter={false} className={classes.deleteButton} />
        </Toolbar>
    );
})

export const TrustPersonBaseEdit = (props) => {
    const {base} = props;
    return (
        <Edit {...props} undoable={false} title={<TrustPersonBaseTitle base={base}/>} >
            <SimpleForm submitOnEnter={false}  toolbar={<CustomToolbar/>}>
                <TextField source="id"/>
                <ReferenceInput  {...props} label="Категория" source="trustPersonCategoryId"
                                 reference="trustPersonCategory"  alwaysOn
                                 perPage={1000} sort={{field: "id", order: "ASC"}}
                                 translateChoice={false} style={{width: "300px"}}
                                 InputLabelProps={{shrink: true,}}
                >
                    <SelectInput optionText="name" optionValue="id"/>
                </ReferenceInput>
                <TextInput fullWidth source="name" label="Название" style={{width: "500px"}}/>
            </SimpleForm>
        </Edit>
    );
}

