import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';

import moment from 'moment-timezone';
import BaseListActions from "../components/BaseListActions";
import OperationAttributeList from "./OperationAttributeList";
import {withTransaction} from "@elastic/apm-rum-react";

const styles = theme => ({
    container: {minWidth: '35em', marginLeft: '1em'},
    rightAlignedCell: {textAlign: 'right'},
    boldCell: {fontWeight: 'bold'},
    head: {
        backgroundColor: "#ededed",
        color: theme.palette.common.white,
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.default,
        },
    }
});

const OperFilter = (props) => {
    return (
        <Filter {...props}>
            <DateInput alwaysOn source="createMoment_gte" label="Дата с"
                       InputProps={{style: {width: "144px", fontSize: "small",height: "48px"}}}
            />
            <DateInput alwaysOn source="createMoment_lte" label="Дата по"
                       InputProps={{style: { width: "144px", fontSize: "small",height: "48px"}}}
            />
            <ReferenceInput alwaysOn  source="operationType.id" reference="operationType"
                            perPage={1000} sort={{field: "name", order: "ASC"}}
                            translateChoice={false}
            >
                <SelectInput label="Тип операции" optionText="name" optionValue="id"
                             InputLabelProps={{shrink: true}} sx={{marginBottom:"0px"}}
                             InputProps={{style: {fontSize: "small", height: "48px"}}}/>

            </ReferenceInput>

            <ReferenceInput alwaysOn  source="operationState.id" reference="operationState"
                            perPage={1000} sort={{field: "name", order: "ASC"}}
                            translateChoice={false}
            >
                <SelectInput label="Результат" optionText="name" optionValue="id"
                    InputLabelProps={{shrink: true}} sx={{marginBottom:"0px"}}
                    InputProps={{style: {fontSize: "small", height: "48px"}}}
                />
            </ReferenceInput>

            <SelectInput alwaysOn label="Мелкочек" source="relyingParty.billingEnabled"
                         choices={[
                             {id: 'true', name: 'Да'},
                             {id: 'false', name: 'Нет'},
                         ]}
                         sx={{marginBottom:"0px"}}
                         translateChoice={false}
                         InputLabelProps={{shrink: true}}
                         InputProps={{style: {fontSize: "small", height: "48px"}}}

            />
            <TextInput label="Контрагент" source="relyingParty.name_like" alwaysOn
                       InputLabelProps={{shrink: true}}
                       InputProps={{style: {fontSize: "small", height: "48px", minWidth: "140px"}}}
            />


        </Filter>
    )
};

class OperationList extends React.PureComponent {

    render() {
        const {...other} = this.props;
        let d = new Date()
        const startDate = moment(d).format("YYYY-MM-DD");
        d.setDate(d.getDate() + 1);
        const stopDate = moment(d).format("YYYY-MM-DD");
        return (
                <List
                    {...other} title="Список операций" filters={<OperFilter/>}
                    perPage={50}
                    sort={{field: 'createMoment', order: 'DESC'}}
                    filterDefaultValues={{createMoment_gte: startDate, createMoment_lte: stopDate}}
                    actions={<BaseListActions name="operation" exportFilename="operations.xlsx"/>}
                >
                            <Datagrid bulkActionButtons={false}
                                expand={<OperationAttributeList />}
                            >
                                <TextField source="id"/>
                                <TextField source="operationNumber" label="Токен"/>
                                <TextField source="operationType.name" label="Тип операции"/>
                                <TextField source="operationState.name" label="Результат"/>
                                <TextField source="relyingParty.name" label="Контрагент"/>
                                <TextField source="serviceSupplier.name" label=" Поставщик"/>
                                <DateField source="createMoment" label="Момент старта" showTime/>
                                <DateField source="modifyMoment" label="Момент завершения" showTime/>
                            </Datagrid>
                        }
                </List>
        )
    }
}

export default withTransaction('OperationList','component')(OperationList);

;