import React from 'react';
import {Datagrid, FunctionField, List, NumberField, TextField} from 'react-admin';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
        title: {
            margin: theme.spacing(2),
        },
        monthCell: {
          width: "120px"
        },
        cell: {
            textAlign: "right",
            //width: "100px",
            //paddingLeft: "1px",
            paddingRight: '5px',
            //borderRight:"1px black solid",
        },
    cellColored: {
        textAlign: "right",
        paddingRight: '5px',
        background: "#f5f5f5",
    },

    cellSeparator: {
            textAlign: "right",
            //width: "100px",
            //paddingLeft: "1px",
            paddingRight: '5px',
            borderRight:"1px black solid",
        },

    })
);


const MelkoStatByService = props => {
    const classes = useStyles(props);

    function _drawCell(value) {

        return (
            value && value > 0 ?
                <strong>{value}</strong>
                :
                <></>
        )
    }

    return (
        <>
            <Typography variant="h6" className={classes.title}>Сводная статистика по подпискам по месяцам</Typography>

            <Typography variant="body2" className={classes.title}>
                "Месяц подписки" - месяц, когда подписка была _добавлена_ "в корзину".<br/>
                "...Всего" - подписки, которые были _добавлены_ "в корзину" в указанном месяце<br/>
                "...Активных" - действующие подписки, по которым лимит запросов НЕ исчерпан<br/>
                "...Ожидают оплаты" - подписки, добавленные в корзину, но неоплаченные<br/>
                "...Завершены" - завершенные подписки, по которым исчерпан лимит запросов<br/>
            </Typography>



            <List resource="melkoStat/byService" basePath=""
                  title="Статистика по мелкочекам"
                  actions={null} pagination={false}

            >
                <Datagrid hover={true} bulkActionButtons={false}>
                    <TextField source="month" label="Месяц подписки" cellClassName={classes.cellSeparator} headerClassName={classes.cell }/>

                    <NumberField source="total" label="Всего" cellClassName={classes.cellSeparator}
                                 sortable={false} headerClassName={classes.cellSeparator}/>

                    <FunctionField label={<>Стартовый<br/>Всего</>} cellClassName={classes.cellColored} headerClassName={classes.cellColored}
                                   render={record => {return record ? _drawCell(record.active200+record.waitPayment200+record.finished200) : ""}}/>
                    <FunctionField label={<>Стартовый<br/>Активных</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? _drawCell(record.active200) : ""}}/>
                    <FunctionField label={<>Стартовый<br/>Ожидают оплаты</>} cellClassName={classes.cell}  headerClassName={classes.cell}
                                   render={record => {return record ? _drawCell(record.waitPayment200) : ""}}/>
                    <FunctionField label={<>Стартовый<br/>Завершены</>} cellClassName={classes.cellSeparator} headerClassName={classes.cellSeparator}
                                   render={record => {return record ? _drawCell(record.finished200) : ""}}/>

                    <FunctionField label={<>Базовый<br/>Всего</>} cellClassName={classes.cellColored} headerClassName={classes.cellColored}
                                   render={record => {return record ? _drawCell(record.active300+record.waitPayment300+record.finished300) : ""}}/>
                    <FunctionField label={<>Базовый<br/>Активных</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? _drawCell(record.active300) : ""}}/>
                    <FunctionField label={<>Базовый<br/>Ожидают оплаты</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? _drawCell(record.waitPayment300) : ""}}/>
                    <FunctionField label={<>Базовый<br/>Завершены</>} cellClassName={classes.cellSeparator} headerClassName={classes.cellSeparator}
                                   render={record => {return record ? _drawCell(record.finished300) : ""}}/>

                    <FunctionField label={<>Премиум<br/>Всего</>} cellClassName={classes.cellColored} headerClassName={classes.cellColored}
                                   render={record => {return record ? _drawCell(record.active400+record.waitPayment400+record.finished400) : ""}}/>
                    <FunctionField label={<>Премиум<br/>Активных</>} headerClassName={classes.cell} cellClassName={classes.cell}
                                   render={record => {return record ? _drawCell(record.active400) : ""}}/>
                    <FunctionField label={<>Премиум<br/>Ожидают оплаты</>} headerClassName={classes.cell} cellClassName={classes.cell}
                                   render={record => {return record ? _drawCell(record.waitPayment400) : ""}}/>
                    <FunctionField label={<>Премиум<br/>Завершены</>} headerClassName={classes.cell} cellClassName={classes.cell}
                                   render={record => {return record ? _drawCell(record.finished400) : ""}}/>

                </Datagrid>

            </List>
        </>
    )
}

export default MelkoStatByService;