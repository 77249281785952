import React, {useState} from 'react';
import classnames from 'classnames';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import Addcon from '@mui/icons-material/PlaylistAdd';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import Paper from '@mui/material/Paper';
import IdxDialog from 'components/dialog';
import PartyPaymentCreate from './party-payment-edit'
import PartyInvoiceCreate from './party-invoice-edit'
import withStyles from '@mui/styles/withStyles';
import moment from 'moment-timezone';
import {useGetList, useNotify, usePermissions, useRefresh} from "react-admin";
import roles from "../../constants/roles";
import {withTransaction} from "@elastic/apm-rum-react";

const styles = theme => ({
    container: {minWidth: '35em', marginLeft: '1em'},
    rightAlignedCell: {textAlign: 'right'},
    boldCell: {fontWeight: 'bold'},
    head: {
        backgroundColor: "#ededed",
        color: theme.palette.common.white,
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    button: {
        marginTop: 7,
        marginLeft: 14,
        marginBottom: 10
    }
});

const formatDate = (date) => {
    let val = null;
    if (date) {
        val = moment(date).format('DD.MM.YYYY hh:mm:ss');
    }
    return val;
}

const PartyPaymentList = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const {permissions} = usePermissions();

    const [isOpenDialogInvoice, setOpenDialogInvoice] = useState(false);

    const [isOpenDialog, setOpenDialog] = useState(false);

    const handleCloseDialog = (isNeedRefresh) => {
        setOpenDialog(false);
        if (isNeedRefresh) {

        }
    }

    const handleCloseDialogInvoce = (isNeedRefresh) => {
        setOpenDialogInvoice(false);
        if (isNeedRefresh) {
            refresh();
        }
    }

    const {partyId, classes} = props;


    const {data, total, isLoading, error} = useGetList(
        'partyPayments', {
            pagination: {page: 1, perPage: 99},

            sort: {field: 'createMoment', order: 'DESC'},
            filter: {"partyId": partyId}
        });

    if (isLoading) return null;
    if (error) {
        notify(error, {type: 'error'});
    }

    if (!data) return null;

    return (
        <React.Fragment>
            <span>
            <Button size="small" variant="contained" color="primary" className={classes.button}
                    onClick={() => setOpenDialog(true)}
                    disabled={permissions.includes(roles.melkocheck_admin)? false:true}
            >Добавить
                <Addcon className={classes.rightIcon}/>

            </Button>
            <Button size="small" variant="contained" color="primary" className={classes.button}
                    onClick={() => setOpenDialogInvoice(true)}
                    disabled={permissions.includes(roles.melkocheck_admin)? false:true}
            >Списание
                <RemoveIcon className={classes.rightIcon}/>
            </Button>
            </span>
            <Paper className={classes.container}>
                <IdxDialog
                    width={400}
                    maxHeight={1400}
                    isOpen={isOpenDialog}
                    mixins={true}
                    onClose={() => handleCloseDialog()}
                    content={
                        <PartyPaymentCreate id={partyId}
                                            onSuccess={() => handleCloseDialog(true)}
                                            location="partyPayment" resource="" basePath=""/>
                    }
                    title="Добавить Платеж"
                />

                <IdxDialog
                    width={400}
                    maxHeight={1400}
                    isOpen={isOpenDialogInvoice}
                    mixins={true}
                    onClose={() => handleCloseDialogInvoce()}
                    content={
                        <PartyInvoiceCreate id={partyId}
                                            onSuccess={() => handleCloseDialogInvoce(true)}
                                            location="partyInvoice" resource="" />
                    }
                    title="Добавить Списание"
                />

                <Table>
                    <TableHead>
                        <TableRow className={classes.head}>
                            <TableCell>ID</TableCell>
                            <TableCell>Дата</TableCell>
                            <TableCell>источник</TableCell>
                            <TableCell>Тип</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Сумма (руб.)</TableCell>
                            <TableCell>Баланс (руб.)</TableCell>
                            <TableCell>Внешний ID</TableCell>
                            <TableCell>Комментарий</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data && data.length > 0
                            ?
                            data.map(
                                item =>
                                    <TableRow className={classes.row} key={item.id}>
                                        <TableCell className={classnames(classes.leftAlignedCell)}>
                                            {item.id}
                                        </TableCell>

                                        <TableCell
                                            className={classnames(classes.leftAlignedCell)}>
                                            {formatDate(item.moment)}
                                        </TableCell>

                                        <TableCell
                                            className={classnames(classes.leftAlignedCell)}>
                                            {item.paymentSource}
                                        </TableCell>

                                        <TableCell
                                            className={classnames(classes.leftAlignedCell)}>
                                            {item.paymentType}
                                        </TableCell>

                                        <TableCell
                                            className={classnames(classes.leftAlignedCell)}>
                                            {item.paymentState}
                                        </TableCell>


                                        <TableCell
                                            className={classnames(
                                                classes.boldCell,
                                                classes.rightAlignedCell
                                            )}
                                        >
                                            {(item.amount / 100).toLocaleString('ru-RU')}
                                        </TableCell>

                                        <TableCell
                                            className={classnames(
                                                classes.boldCell,
                                                classes.rightAlignedCell
                                            )}
                                        >
                                            {(item.balance / 100).toLocaleString('ru-RU')}
                                        </TableCell>

                                        <TableCell
                                            className={classnames(
                                                classes.leftAlignedCell
                                            )}
                                        >
                                            {item.externalId}
                                        </TableCell>

                                        <TableCell
                                            className={classnames(
                                                classes.leftAlignedCell
                                            )}
                                        >
                                            {item.comment}
                                        </TableCell>
                                    </TableRow>
                            )
                            :
                            <TableRow key={'no-data'}>
                                <TableCell>Платежи отсутствуют </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Paper>
        </React.Fragment>
    )
}


export default withStyles(styles)(withTransaction('PartyPaymentList', 'component')(PartyPaymentList));
