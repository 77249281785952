import React from 'react';
import {BooleanInput, Create, DateInput, SimpleForm, TextInput, useRecordContext} from 'react-admin';

const MyTitle = () => {
    const record = useRecordContext();
    return <span>Промокод: {record ? record.code : ''}</span>;
};

// const MyEditToolbar = props => {
//     const location = useLocation();
//     return (
//         <Toolbar {...props}  >
//             {location.pathname.match(/(melko\/promocode\/\d+$)/g) &&
//                 <SaveButton/>
//             }
//             <ListButton label="Назад в список" icon={null}/>
//         </Toolbar>
//     )
// };

const MelkoPromocodeCreate = props => {
    return (
        <Create record={{tenant:"BILLING_2_0", promocodeType:"specSubscription"}} resource="melko/promocode" mutationMode="pessimistic" title="Новый промокод" >
            <SimpleForm>
                                <TextInput label="Тарифная группа" source="tenant"
                                           inputProps={{readOnly: true,}}/>
                <TextInput label="Тип" source="promocodeType"
                           inputProps={{readOnly: true,}}/>


                <TextInput source="code" label="Промокод"/>
                                <TextInput source="name" label="Описание" sx={{width: "60%"}}/>

                                <BooleanInput label="Однократный" source="isOnetime"/>
                                <DateInput label="Начало действия" source="startMoment"/>

                                <DateInput label="Окончание действия" source="stopMoment"/>
            </SimpleForm>
        </Create>
    )
};

export default MelkoPromocodeCreate;