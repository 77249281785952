import React from 'react';
import withStyles from '@mui/styles/withStyles';
import dataProvider from 'components/data-provider';
import {NumberInput, SelectInput, SimpleForm, Toolbar, SaveButton, TextInput, useNotify} from 'react-admin';
import {useQueryClient} from "react-query";
import {OperationTypeLookupEx} from "../components/OperationTypeLookup";

const styles = theme => ({})

const required = value => value != null ? undefined : 'Required';

const TariffCreate = (props) => {
    const notify = useNotify();
    const queryClient = useQueryClient();

    // console.log("PartyPaymentCreate props=", props);

    const handleSave = (tariff) => {
        const {id, onSuccess} = props;
        console.log("here props", props);
        const postData = {
            partyId: id,
            operationTypeId: tariff.operationTypeId,
            fromQty: tariff.fromQty,
            toQty: tariff.toQty,
            price: tariff.price
        };

        dataProvider
            .create('tariff', {data: postData})
            .then(response => {
                queryClient.invalidateQueries({queryKey: ['tariff']});
                onSuccess();
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка добавления тарифа: ${e.message}`, {type: 'error'});
            });
    }

    return (
        <SimpleForm onSubmit={handleSave} toolbar={<Toolbar><SaveButton/></Toolbar>}>
            <OperationTypeLookupEx source="operationTypeId" popupWidth={600}/>
            <span>
            <NumberInput source="fromQty" label="Диапазон проверок, от" validate={required}/>
            <NumberInput source="toQty" label="Диапазон проверок, до"/>
                </span>
            <NumberInput source="price" label="Цена, руб"/>
        </SimpleForm>
    )
}

export const TariffCreateEx = (props) => {
    const notify = useNotify();
    const queryClient = useQueryClient();

    const handleSave = (data) => {
        const {id, onSuccess} = props;


        console.log("formdata", data);
        const postData = {
            partyId: id,
            operationTypeId: data.operationTypeId,
            tariff: data.tariff
        };

        dataProvider
            .create('tariff', {data: postData})
            .then(response => {
                queryClient.invalidateQueries({queryKey: ['tariff']});
                onSuccess();
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка добавления тарифа: ${e.message}`, {type: 'error'});
            });
    }

    return (

        <SimpleForm
            onSubmit={handleSave}
            toolbar={<Toolbar><SaveButton/></Toolbar>} sanitizeEmptyValues={true}>
            <OperationTypeLookupEx source="operationTypeId" popupWidth={600}/>
            <span>
            <NumberInput source="tariff[0].fromQty" label="Диапазон проверок, от" validate={required} defaultValue={0}/>
            <NumberInput source="tariff[0].toQty" label="Диапазон проверок, до" defaultValue={1000}/>
            <NumberInput source="tariff[0].price" label="Цена, руб"/>
            </span>
            <span>
            <NumberInput source="tariff[1].fromQty" label="Диапазон проверок, от" defaultValue={1001}/>
            <NumberInput source="tariff[1].toQty" label="Диапазон проверок, до" defaultValue={10000}/>
            <NumberInput source="tariff[1].price" label="Цена, руб"/>
            </span>
            <span>
            <NumberInput source="tariff[2].fromQty" label="Диапазон проверок, от" defaultValue={10001}/>
            <NumberInput source="tariff[2].toQty" label="Диапазон проверок, до" defaultValue={50000}/>
            <NumberInput source="tariff[2].price" label="Цена, руб"/>
            </span>
            <span>
            <NumberInput source="tariff[3].fromQty" label="Диапазон проверок, от" defaultValue={50001}/>
            <NumberInput source="tariff[3].toQty" label="Диапазон проверок, до" defaultValue={100000}/>
            <NumberInput source="tariff[3].price" label="Цена, руб"/>
            </span>
            <span>
            <NumberInput source="tariff[4].fromQty" label="Диапазон проверок, от" defaultValue={100001}/>
            <NumberInput source="tariff[4].toQty" label="Диапазон проверок, до" />
            <NumberInput source="tariff[4].price" label="Цена, руб"/>
            </span>

        </SimpleForm>
    )
}


export default withStyles(styles)(TariffCreate);
