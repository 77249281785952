import {
    BooleanField,
    Datagrid,
    DeleteButton,
    ListContextProvider,
    TextField,
    useGetList,
    useNotify, usePermissions,
    useRecordContext,
    WrapperField
} from "react-admin";
import {Box, Button, Paper, Table, TableBody, TableCell, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import OperationTypePriceEdit from "./OperationTypePriceEdit";
import PropTypes from "prop-types";
import {Link, useLocation} from "react-router-dom";
import IconContentAdd from "@mui/icons-material/Add";
import React from "react";
import roles from "../constants/roles";


const priceListRowStyle = (record, index) => ({
    backgroundColor: record.isDefault ? 'lightGreen' : 'white',
});


const ExpandedPrices = props => {
    const rec = useRecordContext();
    return (<Box sx={{marginLeft: "30px", marginBottom: "30px"}}>
        <Typography variant="h6">Цены. {rec.name}</Typography>
        <OperationTypePriceEdit priceListId={rec.id}/>
    </Box>)
}

const PartiesField = () => {
    const record = useRecordContext();
    return (record.parties &&
        <WrapperField>
            <Table size="small" sx={{border: 1}}>
                <TableBody>
                    {record.parties.split(',').map((item, index) => {
                        return (
                            <TableRow key={index}>
                                {
                                    item.split("/").map((val, ind) => {
                                        return (
                                            <TableCell key={ind} sx={{border: 1}}>
                                                {val}
                                            </TableCell>)
                                    })
                                }
                            </TableRow>
                        )
                    })
                    }
                </TableBody>
            </Table>

        </WrapperField>
    )
};

const CreatePriceListButton = props => {
    const record = useRecordContext();
    const location = useLocation();
    return (
        <Button {...props}
                component={Link} to={{pathname: '/priceList/create',}}
                state={{
                    record: {serviceSpecId: record.id},
                    returnurl: location
                }}
                sx={{margin: "15px",}}

        >
            <IconContentAdd/>Добавить прайслист
        </Button>
    );
}


const MyEditButton = (props) => {
    const location = useLocation();
    const record = useRecordContext();
    // const ref = useRef();
    // ref.current = '/priceList/' + record.id;
    return (
        <Button {...props}
            component={Link}
            to={{pathname: '/priceList/' + record.id,}}
            state={{
                returnurl: location.pathname
            }}>
            Изменить
        </Button>
    )
}

const MyDeleteButton = (props) => {
    const record = useRecordContext();
    return (<DeleteButton {...props}
                          label="Удалить" resource="priceList"
                          disabled={props.disabled || record.isDefault}
                          redirect={"/service-spec/" + record.serviceSpecId + "/2"}

    />)
}

const PriceListList = (props) => {
    // console.log("PriceListList", props);
    const notify = useNotify();
    const {permissions} = usePermissions();
    const record = useRecordContext();
    const {editablePrices} = props;
    const {data, total, isLoading, error} = useGetList('priceList', {
        pagination: {page: 1, perPage: 1000},
        sort: {field: 'createMoment', order: 'DESC'},
        filter: {"serviceSpecId": record.id}
    });

    if (isLoading) {
        return <p>loading</p>;
    }
    if (error) {
        notify(`Ошибка запроса priceList: ${error}`, {type: 'error'});
        return;
    }
    // console.log("priceLists", data);

    // const ids = Object.keys(data);
    const listProps = {
        data: data, total: total, sort: {}, resource: "priceList", selectedIds: [],

    };

    return (<Paper sx={{marginLeft: "50px", width: (props.width ? props.width : "50%"), background: ""}}>

            <CreatePriceListButton
                disabled={permissions.includes(roles.melkocheck_admin) ? false : true}
            />

            {/*<CreateButton resource="priceList"/>*/}

            {/*<Typography variant="subtitle1">Прайслисты. {record.name}</Typography>*/}
            <ListContextProvider value={listProps} actions={false}>
                <Datagrid
                    bulkActionButtons={false}
                    rowStyle={priceListRowStyle}
                    expand={editablePrices ? <ExpandedPrices/> : null}
                    expandSingle={true}
                >
                    <TextField source="id"/>
                    <BooleanField source="isDefault" label="По умолчанию"/>
                    <TextField source="name" label="Название"/>
                    <PartiesField label="Контрагенты (ID, Название, ИНН)"/>

                    <MyEditButton
                        disabled={permissions.includes(roles.melkocheck_admin) ? false : true}
                    />
                    <MyDeleteButton
                        disabled={permissions.includes(roles.melkocheck_admin) ? false : true}
                    />
                </Datagrid>
            </ListContextProvider>
        </Paper>
    );
};

PriceListList.propTypes = {editablePrices: PropTypes.bool.isRequired}

export default PriceListList;