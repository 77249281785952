import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    ReferenceInput,
    SelectInput,
    TextField
} from 'react-admin';
import {withStyles} from '@mui/styles';
import {withTransaction} from '@elastic/apm-rum-react';
import BaseListActions from "../../components/BaseListActions";
import roles from "../../constants/roles";
import PartyCreateButton from "../party/PartyCreateButton";

const styles = theme => ({
    card: {
        marginBottom: 15
    },

    username: {
        width: '15%'
    },
    boolfld: {
        textAlign: "center",
    },
    numfld: {
        textAlign: "right",
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    comment: {
        width: "20%",
    }
});

const MelkoPaymentFilter = (props) => {
    return (
        <Filter {...props} style={{marginTop: "20px", marginBottom: "20px"}}>
            <DateInput alwaysOn source="createMoment_gte" label="Дата c:"/>
            <DateInput alwaysOn source="createMoment_lte" label="Дата по:"/>

            <ReferenceInput source="paymentSource" reference="paymentSource"
                            alwaysOn translateChoice={false} style={{width: "200px"}} >
                <SelectInput optionText="id" optionValue="id" label="Источник платежа"/>
            </ReferenceInput>

            <ReferenceInput source="paymentType" reference="paymentType"  alwaysOn
                            translateChoice={false} style={{width: "200px"}} >
                <SelectInput optionText="id" optionValue="id" label="Тип платежа"/>
            </ReferenceInput>

            <ReferenceInput  source="paymentState" reference="paymentState"  alwaysOn
                            translateChoice={false} style={{width: "200px"}} >
                <SelectInput optionText="id" optionValue="id" label="Статус платежа"/>
            </ReferenceInput>

        </Filter>
    )
};

const rowStyle = (record, index) => ({
    backgroundColor: (record.payment && record.payment.paymentState == "PROCESSED") ? '#efe' :
        ((record.payment && record.payment.paymentState == "FAILED") ? '#fdd' : 'white')
});

const MelkoPaymentPanel = withStyles(styles)(({record}) => {
    // console.log("props", props);
    console.log("record", record);
    return (
            <>
            {record && (
                <div style={{width: '80%', marginLeft: "80px"}}>
                                <>
                                {record && (record.payment.paymentSource === 'YANDEX' || record.payment.paymentSource==='TINKOFF') &&
                                    <div>
                                        <div>
                                            <span>источник платежа</span>
                                            <span
                                                style={{marginLeft: "30px"}}>{record.payment.paymentSource}</span>
                                        </div>
                                        <div>
                                            <span>тип платежа</span>
                                            <span
                                                style={{marginLeft: "30px"}}>{record.payment.paymentType}</span>
                                        </div>
                                        <div>
                                            <span>externalId</span>
                                            <span
                                                style={{marginLeft: "30px"}}>{record.payment.externalId}</span>
                                        </div>
                                        <div>
                                            <span>externalData</span>
                                            <span style={{marginLeft: "30px"}}>
                                            <pre>{JSON.stringify(record.payment.externalDataObject, null, 2)}</pre>
                                        </span>
                                        </div>
                                    </div>
                                }
                            </>


                </div>

            )}
            </>
    )
})

const MelkoPaymentList = props => {
    const {classes} = props;

    return (
        <List  title="Список платежей мелкочеков" filters={<MelkoPaymentFilter/>} perPage={50}
               actions={<BaseListActions name="melko/payment" exportFilename="платежи.xlsx">

               </BaseListActions>}
        >

            <Datagrid expand={<MelkoPaymentPanel/>} rowClick="expand" rowStyle={rowStyle} hover={true}
                      bulkActionButtons={false}
            >
                <TextField source="payment.id" label="ID" sortBy="id"/>
                <DateField source="payment.moment" label="Дата платежа" sortBy="moment"/>
                <TextField source="party.tenant" label="Биллинг" sortable={false} />
                <TextField source="party.username" label="Контрагент" sortable={false}
                           cellClassName={classes.username}/>
                <TextField source="party.inn" label="ИНН" sortable={false} />
                <TextField source="payment.paymentSource" label="Источник платежа" sortBy="paymentSource"/>
                <TextField source="payment.paymentType" label="Тип платежа" sortBy="paymentType"/>
                <TextField source="payment.paymentState" label="Статус платежа" sortBy="paymentState"/>

                <FunctionField source="payment.amount" label="Сумма платежа, руб" sortBy="amount"
                               cellClassName={classes.numfld}
                               render={record => (record.payment && record.payment.amount) ? `${(record.payment.amount / 100).toFixed(0)}` : ''}/>

                <FunctionField source="payment.balance" label="Неизрасходованный остаток, руб" sortBy="balance"
                               cellClassName={classes.numfld}
                               render={record => (record.payment && record.payment.balance) ? `${(record.payment.balance / 100).toFixed(0)}` : ''}/>

                <TextField source="payment.externalId" label="Внешний ID" sortBy="externalId"/>
                <TextField source="payment.comment" label="Комментарий" sortBy="comment" cellClassName={classes.comment}/>
            </Datagrid>

        </List>
    )
};

export default withStyles(styles)(withTransaction('melko/payment','route-change')(MelkoPaymentList));