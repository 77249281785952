import {Button} from "@mui/material";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";
import PropTypes from "prop-types";
import React from "react";

const CustomSortButton = (props) => {
    const inverseOrder = (sort) => (sort === 'ASC' ? 'DESC' : 'ASC');
    const {field, sort, setSort} = props;
    const handleChangeSort = (event) => {
        if (field) {
            setSort({
                field,
                order: field === sort.field ? inverseOrder(sort.order) : 'ASC'
            });
        }
    };
    return (
        <Button
            onClick={handleChangeSort}
            startIcon={field == sort.field ? (sort.order == 'ASC' ? <ArrowUpIcon/>:<ArrowDownIcon/>): <SortIcon/>}
            endIcon={false}
            size="small"
        />
    );
};
CustomSortButton.propTypes={
    field: PropTypes.string.isRequired,
    sort: PropTypes.object.isRequired,
    setSort: PropTypes.func.isRequired
}

export default CustomSortButton;