import React from 'react';
import {Datagrid, FunctionField, List, TextField} from 'react-admin';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
        title: {
            margin: theme.spacing(2),
        },
        leftTitle: {
            textAlign: "left",
        },
        monthCell: {
          width: "120px"
        },
        cell: {
            textAlign: "right",
            //width: "100px",
            //paddingLeft: "1px",
            paddingRight: '5px',
            //borderRight:"1px black solid",
        },
    cellSeparator: {
            textAlign: "right",
            //width: "100px",
            //paddingLeft: "1px",
            paddingRight: '5px',
            borderRight:"1px black solid",
        },
    })
);


const MelkoStatByOper = props => {
    const classes = useStyles(props);

    function _drawCell(value) {
        return (
            value && value > 0 ?
                <strong>{value}</strong>
                :
                <></>
        )
    }

    return (
        <>
            <Typography variant="h6" className={classes.title}>Сводная статистика по подпискам по месяцам</Typography>

            <Typography variant="body2" className={classes.title}>
                "Месяц подписки" - месяц, когда подписка была куплена.<br/>
                "...Подписок куплено(активно)" - подписки, которые были куплены в указанном месяце(в скобках - еще действующие подписки,т.е. по которым лимит запросов НЕ исчерпан)<br/>
                "...Запросов выполнено" - Кол-во запросов, которое было сделано в рамках купленных в указанном месяце подписок<br/>
                "...Запросов осталось" - Кол-во доступных запросов в рамках купленных в указанном месяце подписок<br/>
            </Typography>



            <List resource="melkoStat/byOper" basePath=""
                  title="Статистика по мелкочекам"
                  actions={null} pagination={false}
            >
                <Datagrid hover={true} bulkActionButtons={false}>
                    <TextField source="month" label="Месяц подписки" cellClassName={classes.cellSeparator} headerClassName={classes.cell }/>

                    <FunctionField label={<>Стартовый<br/>Подписок<br/>куплено(активно)</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? record.paid200 + "("+record.stillActive200+")" : ""}}/>
                    <FunctionField label={<>Стартовый<br/>Запросов выполнено</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? record.operDone200 : ""}}/>
                    <FunctionField label={<>Стартовый<br/>Запросов осталось</>} cellClassName={classes.cellSeparator} headerClassName={classes.cellSeparator}
                                   render={record => {return record ? record.operLeft200 : ""}}/>

                    <FunctionField label={<>Базовый<br/>Подписок<br/>куплено(активно)</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? record.paid300 + "("+record.stillActive300+")" : ""}}/>
                    <FunctionField label={<>Базовый<br/>Запросов выполнено</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? record.operDone300 : ""}}/>
                    <FunctionField label={<>Базовый<br/>Запросов осталось</>} cellClassName={classes.cellSeparator} headerClassName={classes.cellSeparator}
                                   render={record => {return record ? record.operLeft300 : ""}}/>

                    <FunctionField label={<>Премиум<br/>Подписок<br/>куплено(активно)</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? record.paid400 + "("+record.stillActive400+")" : ""}}/>
                    <FunctionField label={<>Премиум<br/>Запросов выполнено</>} cellClassName={classes.cell} headerClassName={classes.cell}
                                   render={record => {return record ? record.operDone400 : ""}}/>
                    <FunctionField label={<>Премиум<br/>Запросов осталось</>} cellClassName={classes.cellSeparator} headerClassName={classes.cellSeparator}
                                   render={record => {return record ? record.operLeft400 : ""}}/>

                </Datagrid>

            </List>
        </>
    )
}

export default MelkoStatByOper;