import React from 'react';
import {
    BooleanInput,
    DateInput,
    Edit,
    EditController,
    ListButton,
    SaveButton,
    TabbedForm,
    TextInput,
    Toolbar,
    useRecordContext
} from 'react-admin';
import {useLocation, useParams} from "react-router-dom";
import MelkoPromocodeRuleList from "./MelkoPromocodeRuleList";
import MelkoPromocodeUsageList from "./MelkoPromocodeUsageList";

const MyTitle = () => {
    const record = useRecordContext();
    return <span>Промокод: {record ? record.code : ''}</span>;
};

const MyEditToolbar = props => {
    const location = useLocation();
    return (
        <Toolbar {...props}  >
            {location.pathname.match(/(melko\/promocode\/\d+$)/g) &&
                <SaveButton/>
            }
            <ListButton label="Назад в список" icon={null}/>
        </Toolbar>
    )
};

const MelkoPromocodeEdit = props => {
    const {id} = useParams();
    return (
        <Edit mutationMode="pessimistic" title={<MyTitle/>}>
            <EditController {...props} >
                {controllerProps => {
                    // console.log("controllerProps", controllerProps);
                    return (
                        <TabbedForm {...controllerProps}
                                    toolbar={<MyEditToolbar/>}
                                    submitOnEnter={false} variant="outlined">

                            <TabbedForm.Tab label="Атрибуты">
                                <TextInput source="id" inputProps={{readOnly: true,}}/>
                                <TextInput label="Тарифная группа" source="tenant"
                                           inputProps={{readOnly: true,}}/>

                                <TextInput source="code" label="Промокод"/>
                                <TextInput source="name" label="Описание" sx={{width: "60%"}}/>

                                <BooleanInput label="Однократный" source="isOnetime"/>
                                <DateInput label="Начало действия" source="startMoment"/>

                                <DateInput label="Окончание действия" source="stopMoment"/>
                            </TabbedForm.Tab>
                            <TabbedForm.Tab label="Настройки">
                                <MelkoPromocodeRuleList promocodeId={Number(id)}/>
                            </TabbedForm.Tab>
                            <TabbedForm.Tab label="Использование">
                                <MelkoPromocodeUsageList promocodeId={Number(id)} />
                            </TabbedForm.Tab>
                        </TabbedForm>
                    )
                }
                }
            </EditController>
        </Edit>
    )
};

export default MelkoPromocodeEdit;