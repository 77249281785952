import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import GoogleButton from 'react-google-button'
import {withStyles} from '@mui/styles';
import LockIcon from '@mui/icons-material/Lock';
import {Notification, useNotify} from 'react-admin';
import {getBasePath} from 'lib/utils'

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        // justifyContent: '',
        background: 'linear-gradient(45deg, gray 0% 10%, lightgray 50% 55%,  darkgray 90% 100%);',
    // 'url(https://source.unsplash.com/random/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',

        justifyContent: 'center',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
        justifyContent: "center",
    },
});

const IdxLogin = (props) =>{

    const [loading, setLoading] = useState(false);
    const notify = useNotify();


    const redirectGoogleLogin = () => {
        const url = `${getBasePath()}/oauth2/authorize/google`;
        window.location.href = url;
    }

    // const checkStatus = (response) => {
    //     setLoading(false);
    //
    //     if (!response.ok) {
    //         notify(`HTTP ${response.status} - ${response.statusText}`);
    //     }
    //     return response;
    // }

        const {classes} = props;
        return (
            <div className={classes.main}>
                <Card className={classes.card} >
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon/>
                        </Avatar>
                    </div>

                    <CardActions className={classes.actions} >
                        <GoogleButton
                            onClick={() => redirectGoogleLogin()}
                        />
                    </CardActions>
                </Card>
                <Notification/>
            </div>
        );
}

export default withStyles(styles)(IdxLogin);
