import React from 'react';
import {Datagrid, DateField, DeleteButton, List, TextField, useRecordContext,} from "react-admin";
import PropTypes from "prop-types";
// import DeleteIcon from '@mui/icons-material/Delete';

const MyDeleteButton = props => {
    const record = useRecordContext();
    return (
        <DeleteButton label="" mutationMode="pessimistic" redirect={"/melko/promocode/" + record.promocodeId + "/1"}/>
    )
}

const MelkoPromocodeUsageList = props => {
    const {promocodeId} = props;
    return (
        <List title={<></>} resource="melko/promocodeUsage" perPage={50}
              empty={false}
              actions={null}
              filter={{promocodeId: promocodeId}}>

            <Datagrid bulkActionButtons={false}>
                <TextField source="id" label="ID"/>
                <TextField source="party.id" label="ID Контрагента"/>
                <TextField source="party.name" label="Название"/>
                <TextField source="party.inn" label="ИНН"/>
                <TextField source="party.username" label="Логин"/>

                <DateField source="createMoment" showTime={true} label="Момент использования промокода"/>

                {/*<DateField source="startMoment" showTime={true} label="Момент начала действия промокода"/>*/}
                {/*<DateField source="stopMoment" label="Дата окончания действия промокода"/>*/}

            </Datagrid>
        </List>
    )
};
MelkoPromocodeUsageList.propTypes = {
    promocodeId: PropTypes.number.isRequired,
}

export default MelkoPromocodeUsageList;