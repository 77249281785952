import React from 'react';
import {BooleanField, Datagrid, DateField, List, NumberField, TextField, TopToolbar} from 'react-admin';
import Button from '@mui/material/Button';
import {connect} from 'react-redux';
import {LOADING_STATE} from 'constants/action-types';
import moment from 'moment-timezone';
import OperationFilter from './operation-filter'
import {getBasePath, jsonToParams} from 'lib/utils'

const exportExcel = (filter, setLoading, showNotification) => {
  const params = {
    filter: filter,
    range: [0, 0],
    sort: ['createMoment', 'ASC' ]
  }

  //const url = new URL(`${getBasePath()}/operation-export-rep1?${jsonToParams(params)}`);
  const url = new URL(`${getBasePath()}/operation-report-excel?${jsonToParams(params)}`);
  const token = localStorage.getItem('token');

  setLoading(true);

  fetch(url, {
    method: 'GET', 
    headers: {
      'authorization': `Bearer ${token}`,
      'content-type':  'application/json',
      'accept': '*/*'
    }
  })
  .then(response => checkStatus(response))
  .then(
    response => response.blob()
  )
  .then(blob => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = "Отчет_операциям.xlsx";
    document.body.appendChild(a);
    a.click();    
    a.remove();      
  })
  .catch(err => { setLoading(false); showNotification(err) });

  const checkStatus = (response) => {
    setLoading(false);

    if (!response.ok) {
      showNotification(`HTTP ${response.status} - ${response.statusText}`);
    }
    return response;
  }
}

const ListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setLoading,
  showNotification,
  ...other
}) => (
  <TopToolbar>
      { bulkActions && React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
      })}
      { filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
      }) }
      <Button color="primary" onClick={ () => exportExcel(filterValues, setLoading, showNotification) }>Export_Excel</Button>
  </TopToolbar>
);

class OperationReport extends React.PureComponent {
  params = { mounted: false };

  componentDidMount() {
    this.params.mounted = true;
  }

  componentDidUpdate() {
    if (this.params.mounted) {
      this.props.setLoading(this.props.loading > 0)
    }
  }

  render() {
    const { setLoading, setLoading2, showNotification, ...other } = this.props;
    const startDate = moment(new Date()).format("YYYY-MM-DD");
    let stopDate = new Date();
    stopDate.setDate(stopDate.getDate() + 1);
    stopDate = moment(stopDate).format("YYYY-MM-DD");

    return (
      <List { ...other } title=", Список операций за период"  filters={ <OperationFilter /> } perPage={30} 
                        actions={<ListActions setLoading={ setLoading } showNotification={ showNotification }/>}
                        filterDefaultValues={{ createMoment_gte: startDate,  createMoment_lte: stopDate}}>

            <Datagrid>
              <DateField source="createMoment" label="Дата операций" locales="fr-FR" />
              <TextField source="partyName" label="Контрагент"/>
              <BooleanField source="partyBillingEnabled" label="Мелкочек" align="center"/>
              <TextField source="operationName" label="Операция"/>
              <TextField source="comment" label="Источник"/>
              <TextField source="displayName" label="Описание"/>
              <NumberField source="good" label="Успешно"/>
              <NumberField source="bad" label="Ошибок"/>
              <NumberField source="notFound" label="Не найдeно"/>
              <NumberField source="cnt" label="Всего"/>
            </Datagrid>
      </List>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.admin.loading
  };
}

const mapDispatchToProps = dispatch => ({
  setLoading: (payload) => { dispatch({ type: LOADING_STATE, payload }) },
  showNotification: (msg, type) => { dispatch(null) },
})

export default connect(mapStateToProps, mapDispatchToProps)(OperationReport);