import React from 'react';
import {BooleanField, Datagrid, EditButton, List, TextField} from 'react-admin';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({

        answer: {
            width: '50%'
        },

})


const FaqList = (props) => {
    const {classes} = props;

    return (
        <List {...props} title="Часто задаваемые вопросы"
              sort={{field: 'orderNum', order: 'ASC'}}
        >
            <Datagrid>
                <TextField source="id" sortable={false}/>
                <TextField source="question" label="Вопрос" sortable={false}/>
                <TextField source="answer" label="Ответ" cellClassName={classes.answer} sortable={false}/>
                <BooleanField source="visible" label="Показывается" sortable={false}/>
                <TextField source="orderNum" label="Порядок" sortable={false}/>
                <EditButton label="Изменить" sortable={false}/>
            </Datagrid>

        </List>
    )
};

export default withStyles(styles)(FaqList);