import React from 'react';
import {Create, FormTab, SelectInput, TabbedForm, TextInput} from 'react-admin';
import withStyles from '@mui/styles/withStyles';

export const styles = {
  user_name: { maxWidth: 450 },
  name: { maxWidth: 600 },
  email: { maxWidth: 550 },
  personName: { maxWidth: 600 },
  phone: { maxWidth: 500 },
  address: { maxWidth: 600 },
};

const required = value => value != null  ? undefined : 'Required';

const PartyCreate = ({ classes, ...props }) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Учетная запись">
        <TextInput source="username" label="Логин"
            formClassName={ classes.user_name } 
            validate={ required } 
            autoFocus 
          />
        <TextInput source="passwordNew" label="Пароль" validate={ required }  />
        <SelectInput source="partyType" label="Тип" validate={ required } choices={[
          { id: "serviceProvider", name: "Контрагент" },
          { id: "idp", name: "Поставщик(IDP)" }
        ]} />
          <SelectInput source="billingEnabled" label="Мелкочек" validate={ required } choices={[
              { id: true, name: "Да" },
              { id: false, name: "Нет" }
          ]} />
      </FormTab>

      <FormTab label="Параметры">
        <TextInput source="name" label="Наименование ораганизации" multiline validate={ required } formClassName={classes.name}/>
        <TextInput source="personName" label="ФИО руководителя" formClassName={ classes.personName } />
        <TextInput source="phone" label="Номер телефона" formClassName={ classes.phone } />
        <TextInput source="email" validate={ required } formClassName={ classes.email } />
        <TextInput source="address" label="Адрес организации" formClassName={ classes.address } />
        <SelectInput source="emailConfirmed" label="Подтверждение email" validate={ required } choices={[
          { id: 'null', name: 'Не подтвержден' },
          { id: 'not_null', name: 'Подтвержден' }
        ]} />
        
        <SelectInput source="active" label="Действующий" validate={ required } choices={[
          { id: 0, name: 'Заморожен' },
          { id: 1, name: 'Активен' }
        ]} />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(PartyCreate);