import React, {Fragment} from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import {withStyles} from '@mui/styles';

const styles = {
    listItem: {
        padding: "1px",
        fontSize: 'small',
        backgroundColor: "#cccccc",
        '& svg': {
            margin: '1px',
        },
        '& div': {
            paddingLeft: '5px',
            minWidth: "32px",
        }
    },

    sidebarIsOpen: {
        paddingLeft: 10,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
};

const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    classes,
    children,
}) => (
    <Fragment>
        <ListItem
            dense
            button
            onClick={handleToggle}
            className={classes.listItem}
        >
            <ListItemIcon >{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
            <ListItemText
                inset
                primary={isOpen ? name : ''}
                secondary={isOpen ? '' : name}
                className={classes.listItemText}
            />
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List
                dense
                component="div"
                disablePadding
                className={
                    sidebarIsOpen
                        ? classes.sidebarIsOpen
                        : classes.sidebarIsClosed
                }
            >
                {children}
            </List>
            <Divider />
        </Collapse>
    </Fragment>
);



export default  withStyles(styles)(SubMenu);
