import React, {
    isValidElement,
    Children,
    createElement,
    useCallback,
    createContext,
} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableFooter
} from '@mui/material';

import union from 'lodash/union';
import difference from 'lodash/difference';
// import DatagridContext from 'ra-ui-materialui';

import {
    DatagridClasses,
    // DatagridContext,
    // DatagridContextValue,

    DatagridRoot,
    DatagridHeader,
    DatagridBody,
    PureDatagridBody,
    DatagridLoading,
    useListContext,
    sanitizeListRestProps,
    BulkActionsToolbar} from 'react-admin';
import PropTypes from "prop-types";
import {cloneElement, useEffect, useMemo, useRef} from "react";
// import DatagridContextProvider from "ra-ui-materialui/src/list/datagrid/DatagridContextProvider";
// import {DatagridClasses, DatagridRoot} from "ra-ui-materialui/src/list/datagrid/useDatagridStyles";
// import {BulkActionsToolbar} from "ra-ui-materialui/src/list/BulkActionsToolbar";
import clsx from "clsx";
// import {BulkDeleteButton} from "ra-ui-materialui/src/button";

// const defaultBulkActionButtons = <BulkDeleteButton />;

import { ReactElement, ReactNode } from 'react';
// var DatagridContext =


const DatagridContextValue = {};
const DatagridContext = createContext({});

DatagridContext.displayName = 'DatagridContext';


export default DatagridContext;

const DatagridContextProvider = ({
    children,
    value,
}) => (
    <DatagridContext.Provider value={value}>
        {children}
    </DatagridContext.Provider>
);

export const DatagridWithFooter = React.forwardRef((props, ref) => {
    const {
        optimized = false,
        body = optimized ? PureDatagridBody : DatagridBody,
        header = DatagridHeader,
        footer,
        children,
        className,
        empty = <></>,
        expand,
        bulkActionButtons,
        hover,
        isRowSelectable,
        isRowExpandable,
        resource,
        rowClick,
        rowStyle,
        size = 'small',
        sx,
        expandSingle = false,
        ...rest
    } = props;

    const {
        sort,
        data,
        isLoading,
        onSelect,
        onToggleItem,
        selectedIds,
        setSort,
        total,
    } = useListContext();

    const hasBulkActions = !!bulkActionButtons !== false;

    const contextValue = useMemo(() => ({ isRowExpandable, expandSingle }), [
        isRowExpandable,
        expandSingle,
    ]);

    const lastSelected = useRef(null);

    useEffect(() => {
        if (!selectedIds || selectedIds.length === 0) {
            lastSelected.current = null;
        }
    }, [JSON.stringify(selectedIds)]); // eslint-disable-line react-hooks/exhaustive-deps

    // we manage row selection at the datagrid level to allow shift+click to select an array of rows
    const handleToggleItem = useCallback(
        (id, event) => {
            const ids = data.map(record => record.id);
            const lastSelectedIndex = ids.indexOf(lastSelected.current);
            lastSelected.current = event.target.checked ? id : null;

            if (event.shiftKey && lastSelectedIndex !== -1) {
                const index = ids.indexOf(id);
                const idsBetweenSelections = ids.slice(
                    Math.min(lastSelectedIndex, index),
                    Math.max(lastSelectedIndex, index) + 1
                );

                const newSelectedIds = event.target.checked
                    ? union(selectedIds, idsBetweenSelections)
                    : difference(selectedIds, idsBetweenSelections);

                onSelect(
                    isRowSelectable
                        ? newSelectedIds.filter((id) =>
                            isRowSelectable(
                                data.find(record => record.id === id)
                            )
                        )
                        : newSelectedIds
                );
            } else {
                onToggleItem(id);
            }
        },
        [data, isRowSelectable, onSelect, onToggleItem, selectedIds]
    );

    if (isLoading === true) {
        return (
            <DatagridLoading
                className={className}
                expand={expand}
                hasBulkActions={hasBulkActions}
                nbChildren={React.Children.count(children)}
                size={size}
            />
        );
    }

    /**
     * Once loaded, the data for the list may be empty. Instead of
     * displaying the table header with zero data rows,
     * the Datagrid displays the empty component.
     */
    if (data == null || data.length === 0 || total === 0) {
        if (empty) {
            return empty;
        }

        return null;
    }

    /**
     * After the initial load, if the data for the list isn't empty,
     * and even if the data is refreshing (e.g. after a filter change),
     * the datagrid displays the current data.
     */
    return (
        <DatagridContextProvider value={contextValue}>
            <DatagridRoot sx={sx} className={DatagridClasses.root}>
                {bulkActionButtons !== false && bulkActionButtons && (
                    <BulkActionsToolbar selectedIds={selectedIds}>
                        {bulkActionButtons}
                    </BulkActionsToolbar>
                )}
                <div className={DatagridClasses.tableWrapper}>
                    <Table
                        ref={ref}
                        className={clsx(DatagridClasses.table, className)}
                        size={size}
                        {...sanitizeRestProps(rest)}
                    >
                        {createOrCloneElement(
                            header,
                            {
                                children,
                                sort,
                                data,
                                hasExpand: !!expand,
                                hasBulkActions,
                                isRowSelectable,
                                onSelect,
                                resource,
                                selectedIds,
                                setSort,
                            },
                            children
                        )}

                        {createOrCloneElement(
                            body,
                            {
                                expand,
                                rowClick,
                                data,
                                hasBulkActions,
                                hover,
                                onToggleItem: handleToggleItem,
                                resource,
                                rowStyle,
                                selectedIds,
                                isRowSelectable,
                            },
                            children
                        )}

                        {createOrCloneElement(
                            footer,
                            {
                                expand,
                                rowClick,
                                data,
                                hasBulkActions,
                                hover,
                                onToggleItem: handleToggleItem,
                                resource,
                                rowStyle,
                                selectedIds,
                                isRowSelectable,
                            },
                            children
                        )}

                    </Table>
                </div>
            </DatagridRoot>
        </DatagridContextProvider>
    );
});

const injectedProps = [
    'isRequired',
    'setFilter',
    'setPagination',
    'limitChoicesToValue',
    'translateChoice',
    // Datagrid may be used as an alternative to SelectInput
    'field',
    'fieldState',
    'formState',
];
const sanitizeRestProps = props =>
    Object.keys(sanitizeListRestProps(props))
        .filter(propName => !injectedProps.includes(propName))
        .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});

const createOrCloneElement = (element, props, children) =>
    isValidElement(element)
        ? cloneElement(element, props, children)
        : createElement(element, props, children);


DatagridWithFooter.defaultProps = {
    data: {},
    hasBulkActions: false,
    ids: [],
    selectedIds: [],
};

DatagridWithFooter.propTypes = {
    basePath: PropTypes.string,
    body: PropTypes.element,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    sort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.object.isRequired,
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool.isRequired,
    hover: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSort: PropTypes.func,
    total: PropTypes.number,
    version: PropTypes.number,
    footer: PropTypes.element
};

