import React from 'react';
import withStyles from '@mui/styles/withStyles';
import dataProvider from 'components/data-provider';
import {NumberInput, SelectInput, SimpleForm, Toolbar, SaveButton, TextInput, useNotify} from 'react-admin';
import {useQueryClient} from "react-query";

const styles = theme => ({})

const required = value => value != null ? undefined : 'Required';

const paymentTypes = [
    {id: 10, comment: 'Авансовый'},
    {id: 20, comment: 'Виртуальный'},
];

const PartyPaymentCreate = (props) => {
    const notify = useNotify();
    const queryClient = useQueryClient();

    // console.log("PartyPaymentCreate props=", props);

    const handleSave = (partyPayment) => {
        const {id, onSuccess} = props;
        console.log("here props", props );
        //На сервер надо передавать сумму в копейках
        const postData = {
            partyId:id,
            paymentTypeId: partyPayment.paymentType,
            amount: partyPayment.amount * 100,
            externalId: partyPayment.externalId,
            comment: partyPayment.comment
        };


        dataProvider
            .create('partyPayment', {data: postData})
            .then(response => {
                queryClient.invalidateQueries({queryKey: ['partyPayments']});
                onSuccess();
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка добавления payment`, {type: 'error'});
            });
    }

    return (
        <SimpleForm onSubmit={handleSave}  toolbar={<Toolbar><SaveButton /></Toolbar>}>
            <SelectInput source="paymentType" label="Тип платежа" choices={paymentTypes} optionText="comment"
                         optionValue="id"/>
            <NumberInput source="amount" label="Сумма (руб.)" validate={required}/>
            <TextInput source="externalId" label="Внешний Id платежа"/>
            <TextInput source="comment" label="Комментарий"/>
        </SimpleForm>
    )
}


export default withStyles(styles)(PartyPaymentCreate);
