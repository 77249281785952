import React from 'react';
import {Datagrid, DeleteButton, List, ReferenceField, TextField, useRecordContext, useRefresh,} from "react-admin";
import PropTypes from "prop-types";
import {MelkoPromocodeRuleCreateButton, MelkoPromocodeRuleEditButton} from "./MelkoPromocodeRuleEdit";
// import DeleteIcon from '@mui/icons-material/Delete';

const MyDeleteButton=props=> {
    const record=useRecordContext();
    return (
        <DeleteButton  label="" mutationMode="pessimistic" redirect={"/melko/promocode/"+record.promocodeId+"/1"} />
    )
}

const MelkoPromocodeRuleList = props => {
    const {promocodeId}= props;
    return (
        <List title={<></>} resource="melko/promocodeRule"  perPage={50}
              empty={false}
              actions={<><MelkoPromocodeRuleCreateButton onChange={useRefresh()}/></>}
              filter={{promocodeId: promocodeId}}>

            <Datagrid bulkActionButtons={false} >
                <TextField source="id" label="ID"/>

                <ReferenceField reference="serviceSpec" source="originalServiceSpecId" label="Исходный тарифный план">
                    <TextField source="name" />
                </ReferenceField>
                
                <ReferenceField reference="serviceSpec" source="targetServiceSpecId" label="Целевой тарифный план">
                    <TextField source="name" />
                </ReferenceField>

                <MelkoPromocodeRuleEditButton   />
                {/*<MelkoPromocodeRuleCreateButton   />*/}

                {/*<EditButton label={false}/>*/}
                <MyDeleteButton />
            </Datagrid>
        </List>
    )
};
MelkoPromocodeRuleList.propTypes = {
    promocodeId: PropTypes.number.isRequired,
}

export default MelkoPromocodeRuleList;