import React, {useState} from 'react';
import {Button, Form, ReferenceInput, SaveButton, SelectInput, useCreate, useNotify, useUpdate} from "react-admin";
import ContentCreate from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconCancel from "@mui/icons-material/Cancel";


const MelkoPromocodeRuleDialog = ({title, isOpen, onClose, onSubmit}) => {
    return (
        <>
            <Dialog
                fullWidth
                open={isOpen}
                onClose={onClose}
            >

                <DialogTitle>{title}</DialogTitle>
                <Form  resource="melkoPromocodeRule" onSubmit={onSubmit}>
                    <>
                        <DialogContent>

                            <ReferenceInput source="originalServiceSpecId" reference="service-spec">
                                <SelectInput label="Исходный тарифный план" optionText="name" optionValue="id"
                                             InputLabelProps={{shrink: true}} sx={{marginBottom: "0px"}}
                                             InputProps={{style: {fontSize: "small", height: "48px"}}}/>
                            </ReferenceInput>

                            <br/>

                            <ReferenceInput source="targetServiceSpecId" reference="service-spec">
                                <SelectInput label="Целевой тарифный план" optionText="name" optionValue="id"
                                             InputLabelProps={{shrink: true}} sx={{marginBottom: "0px"}}
                                             InputProps={{style: {fontSize: "small", height: "48px"}}}/>
                            </ReferenceInput>

                            <br/>
                        </DialogContent>
                        <DialogActions>
                            <Button label="Отмена" onClick={onClose}>
                                <IconCancel/>
                            </Button>
                            <SaveButton/>
                        </DialogActions>
                    </>
                </Form>

            </Dialog>
        </>
    )
}

export const MelkoPromocodeRuleCreateButton = ({props, onChange}) => {
    return (
        <MelkoPromocodeRuleButton isEdit={false} onChange={onChange}/>
    )
}

export const MelkoPromocodeRuleEditButton = ({props, onChange}) => (
    <MelkoPromocodeRuleButton isEdit={true} onChange={onChange} />
)

 const MelkoPromocodeRuleButton = ({isEdit, onChange}) => {
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();

    const [update] = useUpdate();
    const [create] = useCreate();

    const handleOpen = () => {
        setShowDialog(true);
    };
    const handleClose = () => {
        setShowDialog(false);
    };
    const handleUpdateSubmit = async values => {
        update(
            'melko/promocodeRule',
            {id: values.id, data: values},
            {
                onSuccess: ({data}) => {
                    setShowDialog(false);
                    notify("Настройки промокода успешно изменены");
                    if (onChange) {
                        onChange();
                    }
                },
                onError: (error => {
                    notify("Ошибка при изменении настроек промокода: " + (error && error.message ? error.message : ""),
                        {type: 'error', undoable: false, autoHideDuration: 10000});
                })
            }
        );
    };

    const handleCreateSubmit = async values => {
        create(
            'melko/promocodeRule',
            {data: {
                    promocodeId: values.id,
                    originalServiceSpecId: values.originalServiceSpecId,
                    targetServiceSpecId: values.targetServiceSpecId
                }},
            {
                onSuccess: ({data}) => {
                    setShowDialog(false);
                    notify("Настройки промокода успешно добавлены");
                    if (onChange) {
                        onChange();
                    }
                },
                onError: (error => {
                    notify("Ошибка при добавлеии настроек промокода: " + (error && error.message ? error.message : ""),
                        {type: 'error', undoable: false, autoHideDuration: 10000});
                })
            }
        );

    };


    return (
        <>
            {isEdit &&
            <>
                <Button color="primary" onClick={handleOpen} label="">
                    <ContentCreate/>
                </Button>

                <MelkoPromocodeRuleDialog
                    title="Редактирование настроек промокода"
                    onClose={handleClose}
                    onSubmit={handleUpdateSubmit}
                    isOpen={showDialog}/>
            </>
        }
            {!isEdit &&
                <>
                    <Button color="primary" onClick={handleOpen} label="Добавить">
                        <ContentCreate/>
                    </Button>

                    <MelkoPromocodeRuleDialog
                        title="Добавление настроек промокода"
                        onClose={handleClose}
                        onSubmit={handleCreateSubmit}
                        isOpen={showDialog}/>
                </>

            }

        </>
    )
}


//
// const MelkoPromocodeRuleEditButton = ({onChange}) => {
//     const [showDialog, setShowDialog] = useState(false);
//     const [create, {isLoading, error}] = useCreate();
//     const notify = useNotify();
//
//     const handleClick = () => {
//         setShowDialog(true);
//     };
//     const handleCloseClick = () => {
//         setShowDialog(false);
//     };
//     const handleSubmit = async values => {
//         create(
//             'party',
//             {data: values},
//             {
//                 onSuccess: ({data}) => {
//                     setShowDialog(false);
//                     notify("Новый контрагент успешно создан, на указанный email отправлены реквизиты доступа");
//                     if (onChange) {
//                         onChange();
//                     }
//                 },
//                 onError: (error => {
//                     notify("Ошибка при создании контрагента: " + (error && error.message ? error.message : ""),
//                         {type: 'error', undoable: false, autoHideDuration: 10000});
//                 })
//             }
//         );
//     };
//
//     return (
//         <>
//             <Button color="primary" onClick={handleClick} label="Новый контрагент">
//                 <IconContentAdd/>
//             </Button>
//
//             <Dialog
//                 fullWidth
//                 open={showDialog}
//                 onClose={handleCloseClick}
//             >
//                 <DialogTitle>Новый контрагент</DialogTitle>
//                 <Form resource="party" onSubmit={handleSubmit}>
//                     <>
//                         <DialogContent>
//                             <TextInput source="name" label="Название контрагента (латиницей)"
//                                        validate={required()}
//                                        fullWidth
//                             />
//                             <TextInput source="inn" label="ИНН контрагента"
//                                        validate={required()}
//                             />
//                             <br/>
//                             <SelectInput label="Права доступа" source="permission" choices={[
//                                 {id: 'defaultPerm', name: 'Стандартный доступ'},
//                                 {id: 'noPerm', name: 'Без прав доступа'},
//                             ]} translateChoice={false}/>
//                             <TextInput source="email" label="Электронная почта контрагента"
//                                        validate={required()}
//                                        fullWidth
//                             />
//
//                             <Typography variant={"body2"}>
//                                 После создания контрагента на указанный адрес электронной почты будет выслано письмо с
//                                 реквизитами доступа.
//                             </Typography>
//                         </DialogContent>
//                         <DialogActions>
//                             <Button label="Отмена"
//                                     onClick={handleCloseClick}
//                                     disabled={isLoading}>
//                                 <IconCancel/>
//                             </Button>
//                             <SaveButton disabled={isLoading}/>
//                         </DialogActions>
//                     </>
//                 </Form>
//             </Dialog>
//         </>
//     );
//
// }
//
// PartyCreateButton.propTypes = {
//     onChange: PropTypes.func
// }

// export default MelkoPromocodeRuleEditButton;
