import React from 'react';
import {Datagrid, FunctionField, List, TextField} from 'react-admin';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
        title: {
            margin: theme.spacing*2,
        },
        leftTitle: {
            textAlign: "left",
        },
        monthCell: {
          width: "120px"
        },
        cell: {
            textAlign: "right",
            //width: "100px",
            //paddingLeft: "1px",
            paddingRight: '5px',
            borderRight:"1px black solid",
        },
    cellColored: {
        textAlign: "right",
        //width: "100px",
        //paddingLeft: "1px",
        paddingRight: '5px',
        borderRight:"1px black solid",
        background: "#f5f5f5",
    },


    percent: {
            textAlign: "right",
            backgroundColor: "#eee",
            /* dummy gradient with same from and to colours */
            /* you can use any gradient to jazz it up */
            backgroundImage: "linear-gradient(to right, #3399dd, #3399dd)",
            /* because gradients are images, we can use background-size property */
            backgroundSize:
                "1% 100%",         /* initial width of 1% and height 100%*/
            backgroundRepeat:
                "no-repeat",     /* this is important to restrict the gradient */
        },

    })
);

const MelkoStatByParty = props => {
    const classes = useStyles(props);

    function _drawCellWithPercent(value, total1, total2) {
        //if (!value && !total) return;

        let _val1 = total1 == null?
            "-" :
            (total1 == 0 ? '0%' : (value / total1 * 100.0).toFixed(1)+"%");

        let _val2 = total2 == null?
            <>&nbsp;</> :
            (total2 == 0 ? '0%' : (value / total2 * 100.0).toFixed(1)+"%");

        return (
            <>
                <strong>{value}</strong> <br/> {_val1} <br/> {_val2}
            </>
        )
    }

    return (
        <>
            <Typography variant="h6" className={classes.title}>Сводная статистика по мелкочекам по месяцам</Typography>

            <Typography variant="body2" className={classes.title}>
                "Месяц регистрации" - месяц, в котором зарегистрировался пользователь<br/>
                "Зашли на iidx.ru" - кол-во заходов на сайт iidx.ru по данным yandex.metrica<br/>
                "Перешли на регистрацию" - кол-во переходов с iidx.ru на страницу регистрации по данным yandex.metrica<br/>
                "Зарегистрировались" - кол-во зарегистрировавшихся пользователей в указанном месяце<br/>
                "Подтвердили email" - кол-во подтвердивших email из числа тех, кто зарегистрировался в указанном месяце<br/>
                "Попробовали сервис" - кол-во сделавших хотя бы один запрос из числа тех, кто зарегистрировался в указанном месяце<br/>
                "Добавили подписку" - кол-во добавивших подписку в корзину из числа тех, кто зарегистрировался в указанном месяце<br/>
                "Купили подписку" - кол-во купивших подписку(любую) из числа тех, кто зарегистрировался в указанном месяце<br/>
            </Typography>



            <List resource="melkoStat/byParty"
                  title="Статистика по мелкочекам"
                  actions={null} pagination={false}

            >
                <Datagrid hover={true} bulkActionButtons={false}>
                    <TextField source="month" label="Месяц регистрации" cellClassName={classes.cell}/>

{/*
                    <FunctionField label="" headerClassName={classes.percentCell} cellClassName={classes.percentCell}
                                   render={record => {
                                       return record ? (<>Значение<br/>%&nbsp;от&nbsp;регистраций<br/>%&nbsp;от&nbsp;визитов</>): ""
                                   }}/>
*/}


                    {/*
                    <NumberField source="visited" label="Зашли на iidx.ru" className={classes.cell}
                                 sortable={false} headerClassName={classes.cell}/>
*/}
                    <FunctionField label={<>Зашли на iidx.ru<br/><br/>(ya.metrica)</>} headerClassName={classes.cellColored} cellClassName={classes.cellColored}
                                   render={record => {
                                       return record ? _drawCellWithPercent(record.visited, null, null) : ""
                                   }}/>

{/*
                    <NumberField source="registrationVisited" label="Перешли на регистрацию" className={classes.cell}
                                 sortable={false} headerClassName={classes.cell}/>
*/}
                    <FunctionField label={<>Перешли на регистрацию,<br/>% от заходов<br/>(ya.metrica)</>} headerClassName={classes.cell} cellClassName={classes.cell}
                                   render={record => {
                                       return record ? _drawCellWithPercent(record.registrationVisited,  record.visited) : ""
                                   }}/>

                    <FunctionField label={<>Зарегистрировались,<br/>% от переходов,<br/>% от заходов</>} headerClassName={classes.cellColored} cellClassName={classes.cellColored}
                                   render={record => {
                                       return record ? _drawCellWithPercent(record.registered, record.registrationVisited, record.visited) : ""
                                   }}/>

                    <FunctionField label={<span>Подтвердили email,<br/>% от регистраций</span>} headerClassName={classes.cell} cellClassName={classes.cell}
                                   render={record => {
                                       return record ? _drawCellWithPercent(record.emailConfirmed, record.registered) : ""
                                   }}/>

                    <FunctionField label={<span>Попробовали сервис,<br/>% от регистраций</span>} headerClassName={classes.cell} cellClassName={classes.cell}
                                   render={record => {
                                       return record ? _drawCellWithPercent(record.haveOperation, record.registered) : ""
                                   }}/>

                    <FunctionField label={<span>Добавили подписку,<br/>% от регистраций</span>} headerClassName={classes.cell} cellClassName={classes.cell}
                                   render={record => {
                                       return record ? _drawCellWithPercent(record.addedPaidService, record.registered) : ""
                                   }}/>
                    <FunctionField label={<span>Купили подписку,<br/>% от регистраций</span>} headerClassName={classes.cell} cellClassName={classes.cell}
                                   render={record => {
                                       return record ? _drawCellWithPercent(record.paidForService, record.registered) : ""
                                   }}/>

                </Datagrid>

            </List>
        </>
    )
}

export default MelkoStatByParty;