import React from 'react';
import {
    Create,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
// const TrustPersonBaseTitle = ({ record }) => {
//     return <span>Часто задаваемые вопросы: новая запись</span>;
// };
const useStyles = makeStyles(theme => ({
        title: {
            margin: theme.spacing(2),
        },
    })
);


export const TrustPersonBaseCreate = props => {
    const classes = useStyles(props);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(`Новая база в скоринг благонадежности добавлена`)
        redirect('/trustPersonBase');
        //refresh();
    };

    return (
        <>
            <Typography variant="h6" className={classes.title}>Скоринг благонадежности: Новая база</Typography>
            <Create title={<span>Скоринг благонадежности: Новая база</span>}
                    onSuccess={onSuccess}
                    {...props} >
                <SimpleForm>
                    <ReferenceInput  {...props} label="Категория" source="trustPersonCategoryId"
                                     reference="trustPersonCategory"  alwaysOn
                                     perPage={1000} sort={{field: "id", order: "ASC"}}
                                     translateChoice={false} style={{width: "300px"}}
                                     InputLabelProps={{shrink: true,}}
                    >
                        <SelectInput optionText="name" optionValue="id"/>
                    </ReferenceInput>
                    <TextInput source="name" label="База" style={{width: "500px"}}/>
                </SimpleForm>
            </Create>
        </>
    )
}
