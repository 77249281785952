import React from 'react';
import {BooleanInput, Create, NumberInput, SimpleForm, TextInput} from 'react-admin';

const FaqTitle = ({ record }) => {
    return <span>Часто задаваемые вопросы: новая запись</span>;
};

const FaqCreate = props => (
    <Create title={<FaqTitle />} {...props} >
        <SimpleForm>
            <TextInput multiline fullWidth={true} source="question" label="Вопрос"/>
            <TextInput multiline fullWidth={true} source="answer" label={"Ответ"} />
            <BooleanInput source="visible" label={"Активен (не отключен)"}/>
            <NumberInput source="orderNum" label={"Порядковый номер"}/>
        </SimpleForm>
    </Create>
);

export default FaqCreate;