import React, {useEffect} from 'react';
import {Admin, CustomRoutes, localStorageStore, Resource} from 'react-admin';
import './App.css';
import dashboard from './components/dashboard';
import {PartyAdd, PartyEdit, PartyList} from 'pages/party';
import IdxLogin from './layout/IdxLogin';
import OperationList from './pages/OperationList';
import LoadingPopup from 'components/LoadingPopup';
import ServiceSpecList from 'pages/serviceSpec/service-spec-list';
import ServiceSpecEdit from 'pages/serviceSpec/service-spec-edit';
import Report1 from 'pages/reports/operation-report-1'
import FaqList from "./pages/faq/faqList";
import FaqEdit from "./pages/faq/faqEdit";
import FaqCreate from "./pages/faq/faqCreate";
import MelkoPartyList from "./pages/melko/MelkoPartyList";
import {default as MelkoPartyEdit} from 'pages/party/PartyEdit';
import MelkoPaymentList from "./pages/melko/MelkoPaymentList";
import MelkoPaymentView from "./pages/melko/MelkoPaymentShow";
import OperationStatList from "./pages/OperationStatList";
import OperationTypeList from "./pages/OperationTypeList";
import OperationTypeEdit from "./pages/OperationTypeEdit";
import {Route, useNavigate} from 'react-router-dom';
import OperationTokenList from "./pages/OperationTokenList";
import OperationAttributeList from "./pages/OperationAttributeList";
import SolarisStat from "./pages/SolarisStat";
import OperationStatByTypeList from "./pages/OperationStatByTypeList";
import theme from "./layout/Theme";
import OperationStatByPartyList from "./pages/OperationStatByPartyList";
import MelkoStat from "./pages/melko/MelkoStat";
import MelkoStatByParty from "./pages/melko/MelkoStatByParty";
import MelkoStatByService from "./pages/melko/MelkoStatByService";
import MelkoStatByOper from "./pages/melko/MelkoStatByOper";
import {
    OperationLogStatList,
    OperationLogStatListBasis,
    OperationLogStatListDbrain,
    OperationLogStatListEsia,
    OperationLogStatListFns,
    OperationLogStatListIds,
    OperationLogStatListIdx, OperationLogStatListIdxData,
    OperationLogStatListNbki,
    OperationLogStatListOkb,
    OperationLogStatListOsnovanie,
    OperationLogStatListParserAPi,
    OperationLogStatListReputation,
    OperationLogStatListRscb,
    OperationLogStatListSinara,
    OperationLogStatListSmev
} from "./pages/OperationLogStatList";
import {TrustPersonBaseList} from "./pages/TrustPersonBaseList";
import {TrustPersonBaseEdit} from "./pages/TrustPersonBaseEdit";
import {TrustPersonBaseCreate} from "./pages/TrustPersonBaseCreate";
import {IdxLayout} from "./layout/IdxLayout";
import myProvider from "components/data-provider";
import PriceListEdit from "./pages/PriceListEdit";
import PriceListCreate from "./pages/PriceListCreate";
import MelkoPaymentRequestList from "./pages/melko/MelkoPaymentRequestList";
import MelkoPromocodeList from "./pages/melko/MelkoPromocodeList";
import MelkoPromocodeEdit from "./pages/melko/MelkoPromocodeEdit";
import MelkoPromocodeRuleList from "./pages/melko/MelkoPromocodeRuleList";
import MelkoPromocodeCreate from "./pages/melko/MelkoPromocodeCreate";
import {authProviderNew} from "./components/authProviderNew";
import {decodeToken} from "react-jwt";
import {withTransaction} from "@elastic/apm-rum-react";
import {PartyStatMonthlyPartyList} from "./pages/PartyStatMonthlyPartyList";

const App = () => {
    const store = localStorageStore();

    const navigate = useNavigate();
    const dataProvider = myProvider;

    useEffect(() => {
        if (window.location.pathname === "/newToken") {
            console.log("here new token");
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams && urlParams.get('token')) {
                const token = urlParams.get('token');
                console.log("token", token);
                if (decodeToken(token)) {
                    localStorage.setItem('token', token);
                    window.history.pushState(null, null, "/");
                    navigate("/dashboard");
                }
            }
        }
    }, []);

     if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        // <>
        /*
            <Provider store=configureStore(){createStore(combineReducers(partyAutocomplete))}>
*/
                <Admin dataProvider={dataProvider}
                       authProvider={authProviderNew}
                       theme={theme}
                       loginPage={IdxLogin}
                       title="IDX ADMIN"
                       layout={IdxLayout}
                       dashboard={dashboard}
                       appLayout={IdxLayout}
                       store={store}
                       requireAuth
                       disableTelemetry
                >
                    <CustomRoutes>
                        <Route exact path="/operationSearch" element={<OperationTokenList/>}/>,
                        <Route exact path="/solarisStat" element={<SolarisStat/>}/>,
                        <Route exact path="/melkoStat" element={<MelkoStat/>}/>,
                        <Route exact path="/partyStatMonthly/partyList"
                               element = {<PartyStatMonthlyPartyList />} />
                    </CustomRoutes>


                    <Resource name="loadingPopup" list={LoadingPopup}/>
                    <Resource name="dashboard" list={dashboard}/>
                    <Resource name="melko/party" list={MelkoPartyList} edit={MelkoPartyEdit}/>
                    <Resource name="melko/payment" list={MelkoPaymentList} view={MelkoPaymentView}/>
                    <Resource name="melko/paymentRequest" list={MelkoPaymentRequestList}/>
                    <Resource name="melko/promocodeRule" list={MelkoPromocodeRuleList}/>
                    <Resource name="melko/promocode" list={MelkoPromocodeList} edit={MelkoPromocodeEdit}
                              create={MelkoPromocodeCreate}/>


                    <Resource name="melkoStat/byParty" list={MelkoStatByParty}/>
                    <Resource name="melkoStat/byService" list={MelkoStatByService}/>
                    <Resource name="melkoStat/byOper" list={MelkoStatByOper}/>

                    <Resource name="trustPersonBase" list={TrustPersonBaseList} edit={TrustPersonBaseEdit}
                              create={TrustPersonBaseCreate}/>
                    <Resource name="trustPersonCategory"/>
                    <Resource name="paymentSource"/>
                    <Resource name="paymentType"/>
                    <Resource name="paymentState"/>
                    <Resource name="operationType" list={OperationTypeList} edit={OperationTypeEdit}/>
                    <Resource name="operationState"/>
                    <Resource name="priceList" edit={PriceListEdit} create={PriceListCreate}/>
                    <Resource name="partyInPriceList"/>


                    <Resource name="operationToken" list={OperationTokenList}/>
                    <Resource name="operationAttribute" list={OperationAttributeList}/>

                    <Resource name="party" list={PartyList} edit={PartyEdit} create={PartyAdd}/>
                    <Resource name="service-spec" list={ServiceSpecList} edit={ServiceSpecEdit}/>
                    <Resource name="operation" list={OperationList}/>
                    <Resource name="operationStatbyType" list={OperationStatByTypeList}/>
                    <Resource name="operationStatbyParty" list={OperationStatByPartyList}/>
                    <Resource name="operationStat" list={OperationStatList}/>

                    <Resource name="operationLogStatNbki" list={OperationLogStatListNbki}/>
                    <Resource name="operationLogStatOkb" list={OperationLogStatListOkb}/>
                    <Resource name="operationLogStatRscb" list={OperationLogStatListRscb}/>
                    <Resource name="operationLogStatEsia" list={OperationLogStatListEsia}/>
                    <Resource name="operationLogStatParserApi" list={OperationLogStatListParserAPi}/>
                    <Resource name="operationLogStatDbrain" list={OperationLogStatListDbrain}/>
                    <Resource name="operationLogStatOsnovanie" list={OperationLogStatListOsnovanie}/>
                    <Resource name="operationLogStatSmev" list={OperationLogStatListSmev}/>
                    <Resource name="operationLogStatSinara" list={OperationLogStatListSinara}/>
                    <Resource name="operationLogStatFns" list={OperationLogStatListFns}/>
                    <Resource name="operationLogStatIds" list={OperationLogStatListIds}/>
                    <Resource name="operationLogStatIdx" list={OperationLogStatListIdx}/>
                    <Resource name="operationLogStatIdxData" list={OperationLogStatListIdxData}/>

                    <Resource name="operationLogStatReputation" list={OperationLogStatListReputation}/>
                    <Resource name="operationLogStatBasis" list={OperationLogStatListBasis}/>
                    <Resource name="operationLogStatSolaris" list={<OperationLogStatList idp="solaris"/>}/>


                    <Resource name="operation-report" list={Report1}/>
                    {/*<Resource name="nbki-report" list={ReportNbki}/>*/}

                    <Resource name="service-specType-list"/>
                    <Resource name="faq" list={FaqList} edit={FaqEdit} create={FaqCreate}/>

                </Admin>
/*

            </Provider>
*/

        // </>
    );
    // }
}

export default App;
