import {getBasePath} from 'lib/utils';
import {decodeToken} from "react-jwt";
import {httpClient} from "./data-provider";


export const authProviderNew = {
    login: ({username, password}) => {
        const request = new Request(`${getBasePath()}/login`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                console.log("response from login", response);
                return response.json();
            })
            .then(({token}) => {
                localStorage.setItem('token', token);
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkAuth: (params) => {
        //console.log("checkAuth token=", localStorage.getItem('token'));

        // return localStorage.getItem("token") ?
        //     authProviderNew.checkAuthServer() :
        //     Promise.reject("Требуется авторизация");

                // .then(() =>{console.log("heree22");
                //     return Promise.resolve();
                // });
        // }
        // return Promise.reject("Требуется авторизация");

        return localStorage.getItem('token') ?
            Promise.resolve() :
            Promise.reject({message: 'Требуется авторизация'})
    },
    checkError: (error) => {
        console.log("checkError", error);
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject({redirectTo: '/login', logoutUser: true, message: "Требуется авторизация"});
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },

    getIdentity: () => {
        try {
            // console.log("getIdentity", localStorage.getItem('token'));
            const decoded = decodeToken(localStorage.getItem('token'));
            if (decoded)
                return Promise.resolve(decoded.sub);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: (params) => {
        // console.log("getPermissions ", params);
        if (localStorage.getItem('token')) {
            const decoded = decodeToken(localStorage.getItem('token'));
            console.log(decoded);
            return Promise.resolve(decoded.roles);
        }
        return Promise.reject("Нет прав");
    },

    checkAuthServer () {
        console.log("in checkAuthServer");
        return httpClient(`${getBasePath()}/checkAuth`);
    }

}