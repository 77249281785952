import React from 'react';
import {Datagrid, ListContextProvider, TextField, useGetList,} from 'react-admin';
import {Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useDatagridStyles = makeStyles({
    headerCell: {
        backgroundColor: '#f0f0f0',
    },
    row: {
        backgroundColor: '#f0f0f0',
    },
});

const OperationAttributeList = (props) => {
    const operationId = props.id;
    const {data, total, isLoading, error} = useGetList('operationAttribute',
        {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'id', order: 'ASC'},
            filter: {"operationId": operationId},
        });

    if (isLoading) {
        return null;
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }
    /*if (total == 0) { return <p>Нет данных</p>     }*/
    if (!data) {
        return null;
    }

    return (

        <Paper style={{marginLeft: "50px", width: "70%", background: "#f0f0f0"}}>
            {total == 0 ?
                <div style={{padding: "10px"}}>Нет данных</div>
                :
                <CustomList data={data} total={total}/>
            }
        </Paper>
    )

}

// data, total, page, perPage, setPage, filterValues, setFilters, sort
const CustomList = (props) => {
    const {data, total} = props;
    const listProps = {
        data,
        total,
        sort: {},
        resource: ""
    };

    const classes = useDatagridStyles();
    return (

        <ListContextProvider value={listProps}>
            <Datagrid classes={classes} isRowSelectable={() => {
                return false;
            }}
                      rowStyle={(record) => ({
                          backgroundColor: record &&
                          record.attribute.attribute &&
                          record.attribute.attribute.startsWith("response") ? 'lightGreen' : null
                      })}
            >
                <TextField source="id" sortable={false}/>
                <TextField source="attribute.attribute" label="Аттрибут" sortable={false}/>
                <TextField source="attribute.name" label="Название" sortable={false}/>
                <TextField source="value" label="Значение" sortable={false}/>
            </Datagrid>

        </ListContextProvider>
    )
}

export default OperationAttributeList;