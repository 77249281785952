import React from "react";
import {Datagrid, DateField, FunctionField, List, TextField, usePermissions, useRefresh,} from 'react-admin';
import BoolNumField from '../../components/bool-num-field';
import BaseListActions from "../../components/BaseListActions";
import PartyCreateButton from "./PartyCreateButton";
import {PartyFilter} from './party-filter';
import {withTransaction} from "@elastic/apm-rum-react";
import roles from "../../constants/roles";


const PartyList = (props) => {
    const refresh = useRefresh();
    const {permissions} = usePermissions();

    return (

        <List title="Список контрагентов" filters={<PartyFilter/>} perPage={50}
              actions={<BaseListActions name="party" exportFilename="party_list.xlsx">
                  {permissions.includes(roles.party_admin) &&
                      <PartyCreateButton onChange={refresh}/>
                  }
              </BaseListActions>}
        >
            <Datagrid {...props} rowClick="edit" bulkActionButtons={false}>
                <TextField source="id"/>
                <TextField source="username" label="Email/Логин"/>
                {/*<TextField source="email" label="Email/логин" />*/}
                {/*<TextField source="name" label="Название"/>*/}
                <FunctionField label="Название" sortBy="name"
                               render={record => {
                                   return (
                                       <span>{record.name}
                                           {record.isTest ?
                                               <span style={{color: "red"}}><br/>ТЕСТОВЫЙ</span> : ""}
                                       </span>
                                   )
                               }}
                />

                <TextField source="inn" label="ИНН"/>
                {/*
                        <TextField source="personName" label="Контактное лицо"/>
                        <TextField source="phone" label="Телефон"/>
*/}
                <TextField source="partyType" label="Тип"/>
                <BoolNumField source="isLegal" label="Юр.лицо"/>
                <BoolNumField source="emailConfirmed" label="Email подтвержден"/>
                <BoolNumField source="active" label="Активен"/>
                <BoolNumField source="billingEnabled" label="Мелкочек"/>
                <DateField source="createMoment" label="Дата регистрации"/>
                {/*          <DateField source="modifyMoment" label="Дата изменения"/>*/}
                {/*<EditButton label="Изменить"/>*/}
            </Datagrid>
        </List>
    )
};


export default withTransaction('Все_контрагенты', 'component')(PartyList);