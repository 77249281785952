import React from 'react';
import classnames from 'classnames';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Clear';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import withStyles from '@mui/styles/withStyles';
import dataProvider from '../../components/data-provider';
import moment from 'moment-timezone';
import MyEditableLabel from "../../components/MyEditableLabel";
import AlertDialog from "../../components/AlertDialog";
import {useNotify, usePermissions} from "react-admin";
import {useImmer} from "use-immer";
import roles from "../../constants/roles";
import {withTransaction} from "@elastic/apm-rum-react";


const styles = theme => ({
    container: {minWidth: '600px', marginLeft: '1em'},
    rightAlignedCell: {textAlign: 'right'},
    boldCell: {fontWeight: 'bold'},
    tightCell: {width: '70px'},
    centerCell: {textAlign: 'center'},
    head: {
        backgroundColor: "#ededed",
        color: theme.palette.common.white,
        '& th': {
            padding: '4px',
        }
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.default,
        },
        '& td': {
            padding: '4px',
        }
    },

    button: {
        marginTop: 7,
        marginLeft: 14,
        marginBottom: 10
    }
});

const formatDate = (date) => {
    let val = null;
    if (date) {
        val = moment(date).format('DD.MM.YYYY hh:mm:ss');
    }
    return val;
}


/**
 * См. PartyPermissionLimitController
 * Вытаскиваем смешаный resultset из PartyPermission и PartyOperationLimit
 */
const PartyPermissionLimitList = (props) => {
    // console.log('partyPerm props=',props);
    const {partyId, classes} = props;
    const billingEnabled = props.record.billingEnabled;
    const {permissions} = usePermissions();
    const allowEdit = !billingEnabled && permissions.includes(roles.party_admin);
    const notify = useNotify();

    const [records, setRecords] = useImmer(null);


    const fetchData = () => {
        //console.log("partyPermissionLimitList.fetchData");
        dataProvider.getList('partyPermissionLimit/' + partyId, {
            // target: 'partyId',
            // id: partyId,
            pagination: {page: 1, perPage: 500},
            sort: {field: 'id', order: 'ASC'}
        })
            .then(response => {
                setRecords(response && response.data);
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка запроса partyPermissionLimit: ${e}`, 'warning');
            });
    }

    const handlePermChange = recNum => event => {
        let val = event.target.checked;
        const operationTypeId = records[recNum].operationTypeId;
        dataProvider
            .update('partyPermissionLimit', {
                id: partyId,
                data: {operationTypeId: operationTypeId, editType: 'permission', permission: val}
            })
            .then(response => {
                setRecords(draft => {
                    draft[recNum].permission = val
                });
                notify('Права доступа изменены', {autoHideDuration: 2000});
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка при изменении прав доступа:  ${e}`, {type: 'error'});
            });
    }

    const handleLimitEdit = (ref, recNum, typ, val) => {
        //сначала проверяется правильность ввода (цифры) и сравнение со старым значением
        if (isNaN(val)) {
            val = typ == "current" ? records[recNum].current : records[recNum].maxLimit;
            ref.setState({text: (val == null ? '-' : val)});
            return;
        }
        if (val == '' || val == null) val = 0;

        let oldVal = typ == "current" ? records[recNum].current : records[recNum].maxLimit;
        if (oldVal == null) oldVal = 0;
        //console.log("oldVal=", oldVal, "val=", val);
        if (oldVal == val) {
            ref.setState({text: (oldVal == "" ? '-' : oldVal)})
            return;
        }
        // потом отправка на сервер
        const operationTypeId = records[recNum].operationTypeId;
        const data = {
            operationTypeId: operationTypeId,
            current: typ == 'current' ? val : null,
            maxLimit: typ == 'maxLimit' ? val : null,
            editType: typ
        }

        dataProvider
            .update('partyPermissionLimit', {id: partyId, data: data})
            .then(response => {
                if (typ == 'current') {
                    setRecords(draft => {
                        draft[recNum].current = val;
                        if (draft[recNum].maxLimit == null) draft[recNum].maxLimit = 0;
                    });
                    // records[recNum].current = val;
                    // if (records[recNum].maxLimit == null) records[recNum].maxLimit = 0;
                } else {
                    setRecords(draft => {
                        draft[recNum].maxLimit = val;
                        if (draft[recNum].current == null) draft[recNum].current = 0;
                    });
                    // records[recNum].maxLimit = val;
                    // if (records[recNum].current == null) records[recNum].current = 0;
                }

                notify('Значение успешно изменено', {autoHideDuration: 2000});
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка при редактировании: ${e}`, {type: 'error'});
            });

    }

    const handleClearLimit = (e, recNum) => {
        e.preventDefault();
        if (!window.confirm("Пожалуйста, подтвердите снятие лимитов для вызова " + records[recNum].operationTypeName)) {
            return;
        }
        const {partyId} = props;
        const operationTypeId = records[recNum].operationTypeId;
        //console.log("clearing limits for oper=", operationTypeId);
        //calling data provider
        const data = {
            operationTypeId: operationTypeId,
            editType: "clearLimits"
        }
        dataProvider
            .update('partyPermissionLimit', {id: partyId, data: data})
            .then(response => {
                setRecords(draft => {
                    draft[recNum].current = null;
                    draft[recNum].maxLimit = null;
                });
                notify('Лимиты успешно сброшены', {autoHideDuration: 2000});
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка при удалении лимитов: ${e}`, {type: 'error'});
            });


    }
    if (!records) {
        fetchData();
    }

    if (!records) return null;
    return (

        <React.Fragment>
            {
                !allowEdit ?
                    <div style={{margin: "10px", color: "Red"}}>
                        Редактирование прав доступа запрещено

                    </div>
                    : (billingEnabled ?
                        <div style={{margin: "10px", color: "Red"}}>
                            Редактирование прав доступа запрещено для мелкочека
                        </div>
                        :
                        <div style={{margin: "10px"}}>
                            &nbsp;
                        </div>)
            }

            <Paper className={classes.container}>


                <AlertDialog title="Удаление лимитов"
                             description={"Вы действительно хотите удалить лимиты?"}
                             onConfirm={handleClearLimit}>
                </AlertDialog>


                <Table>
                    <TableHead>
                        <TableRow className={classes.head}>
                            <TableCell style={{maxWidth: '50px', textAlign: 'center'}}>ID
                            </TableCell>
                            <TableCell>Операция</TableCell>
                            <TableCell>Источник</TableCell>
                            <TableCell style={{maxWidth: '250px'}}>Описание</TableCell>
                            <TableCell className={classes.centerCell}>Права доступа</TableCell>
                            <TableCell className={classes.centerCell}>Счетчик вызовов</TableCell>
                            <TableCell className={classes.centerCell}>Лимит</TableCell>
                            <TableCell className={classes.centerCell}></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {records && records.length > 0
                            ?
                            records.map(
                                (item, num) =>
                                    <TableRow className={classes.row} key={item.operationTypeId}>

                                        <TableCell className={classes.centerCell}>
                                            {item.operationTypeId}
                                        </TableCell>

                                        <TableCell className={classnames(classes.leftAlignedCell)}
                                                   style={{width: '200px'}}>
                                            {item.operationTypeName}
                                        </TableCell>

                                        <TableCell
                                            className={classnames(classes.leftAlignedCell, classes.tightCell)}>
                                            {item.operationTypeComment}
                                        </TableCell>

                                        <TableCell className={classnames(classes.leftAlignedCell)}>
                                            {item.operationTypeDisplayName}
                                        </TableCell>

                                        <TableCell className={classnames(classes.centerCell)}>
                                            {allowEdit ?
                                                <Switch
                                                    checked={item.permission != null && item.permission == true}
                                                    onChange={handlePermChange(num)}
                                                    value={"perm_" + item.id}
                                                    color="primary"
                                                    size="small"
                                                    // inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                :
                                                item.permission && item.permission == true ? <CheckIcon/> :
                                                    <CrossIcon/>
                                            }
                                        </TableCell>

                                        <TableCell className={classnames(classes.centerCell)}>
                                            {allowEdit ?

                                                <MyEditableLabel
                                                    text={item.current == null ? '-' : "" + item.current}
                                                    inputWidth='50px'
                                                    inputHeight='20px'
                                                    inputMaxLength={6}
                                                    onFocus={(ref, val) => {
                                                        if (val == '-') ref.state.text = '';
                                                    }}
                                                    onFocusOut={(ref, val) => {
                                                        handleLimitEdit(ref, num, 'current', val)
                                                    }}
                                                />
                                                :
                                                item.current == null ? '-' : item.current
                                            }
                                        </TableCell>

                                        <TableCell className={classnames(classes.centerCell)}>
                                            {allowEdit ?
                                                <React.Fragment>
                                                    <MyEditableLabel
                                                        text={item.maxLimit == null ? '-' : "" + item.maxLimit}
                                                        inputWidth='50px'
                                                        inputHeight='20px'
                                                        inputMaxLength={6}
                                                        onFocus={(ref, val) => {
                                                            if (val == '-') ref.state.text = '';
                                                        }}
                                                        onFocusOut={(ref, val) => {
                                                            handleLimitEdit(ref, num, 'maxLimit', val)
                                                        }}
                                                    />

                                                </React.Fragment>
                                                :
                                                item.maxLimit == null ? '-' : item.maxLimit
                                            }

                                        </TableCell>
                                        <TableCell className={classnames(classes.centerCell)}>
                                            {allowEdit && item.maxLimit != null &&
                                                <Link style={{marginTop: "10px"}} href="#"
                                                      onClick={(ev) => {
                                                          ev.preventDefault();
                                                          handleClearLimit(ev, num)
                                                      }}>
                                                    Сбросить
                                                </Link>
                                            }
                                        </TableCell>

                                    </TableRow>
                            )
                            :
                            <TableRow key={'no-data'}>
                                <TableCell>Что-то пошло не так </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Paper>
        </React.Fragment>
    );
}

export default withStyles(styles)(withTransaction('PartyPermissionLimitList', 'component')(PartyPermissionLimitList));