import {
    BulkDeleteButton,
    Datagrid,
    ListContextProvider,
    TextField,
    useCreate,
    useGetList,
    useList,
    useNotify, usePermissions,
    useRecordContext,
    useRefresh
} from "react-admin";
import {Paper} from "@mui/material";

import {PartyLookupButton} from "./party/party-lookup";
import roles from "../constants/roles";


const PartyInPriceListList = (props) => {
    // console.log("PartyInPriceListList", props);
    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const [create] = useCreate();
    const {permissions} = usePermissions();

    const {data, total, isLoading, error} = useGetList('partyInPriceList', {
        pagination: {page: 1, perPage: 1000},
        sort: {field: 'name', order: 'DESC'},
        filter: {"priceListId": record.id}
    });

    const listContext = useList({ data, total, isLoading, page:1, resource:"partyInPriceList" });

    if (isLoading) {
        return <p>loading</p>;
    }
    if (error) {
        notify(`Ошибка запроса partyInPriceList: ${error}`, {type: 'error'});
        return;
    }

    const handlePartyAdd = id => {
        create('partyInPriceList',
            {data: {priceListId: record.id, partyId: id}},
            {
                onSuccess: (() => {
                    refresh();
                }),
                onError: (error => {
                    notify("Ошибка при добавлении: "+error, {type: 'error'})
                })
            });
    }

    return (

        <>
            <PartyLookupButton buttonLabel="Добавить контрагента"
                               dialogTitle="Выберите контрагента для добавления к прайслисту"
                               onPartySelect={handlePartyAdd}
                                disabled={permissions.includes(roles.melkocheck_admin) ?false:true}
            />
            <Paper sx={{marginLeft: "20px", width: (props.width ? props.width : "50%"),}}>
                <ListContextProvider value={listContext} >
                    <Datagrid
                        bulkActionButtons={<>
                            {permissions.includes(roles.melkocheck_admin) &&
                                <BulkDeleteButton label="Удалить" resource="partyInPriceList"/>
                            }
                        </>} >
                        <TextField source="id"/>
                        <TextField source="party.id" label="ID контрагента"/>
                        <TextField source="party.inn" label="ИНН контрагента"/>
                        <TextField source="party.name" label="Название"/>

                    </Datagrid>
                </ListContextProvider>
            </Paper>
        </>
    );
};

export default PartyInPriceListList;