import {
    FunctionField,
    TextField,
    Button, useGetList,
    ListContextProvider, Pagination, useNotify,
} from "react-admin";
import {formatMoney} from "../lib/utils";
import React, {Children, useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import dataProvider from "../components/data-provider";
import {useQueryClient} from "react-query";
import ReplayIcon from '@mui/icons-material/Replay';
import ExportExcelButton from "../components/ExportExcelButton";
import {DatagridWithFooter} from "../components/DatagridWithFooter";
import {formatMonth, PartyStatFooter, PartyStatMonthlyFilter} from "./PartyStatMonthlyHelpers";





const rowstyle = (record, index) => ({
    backgroundColor: record.price == 0 ? '#fee' : 'white',
})


/**
 * Показывается отчет по одному контрагенту
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const PartyStatMonthlyList = (props) => {
    const {partyId, period, showFilter} = props;
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(1);
    const perPage = 1000;
    const sort = {field: 'id', order: 'ASC'};

    const [filterValues, setFilters] = useState({period: period, partyId: partyId});

    const {data, total, isLoading} = useGetList('partyStatMonthly', {
        pagination: {page: page, perPage: perPage},
        sort: sort,
        filter: filterValues,
    });
    const recalcParty = () => {
        dataProvider.customUpdate("partyStatMonthly/recalcParty", {
            id: null,
            method: "POST",
            data: {
                partyId: filterValues.partyId,
                period: filterValues.period
            }
        }).then((response) => {
            queryClient.invalidateQueries({queryKey: ['partyStatMonthly']});
            notify("Отчет пересчитан");
        }).catch((error) => {
            notify("Ошибка: " + error, {type: 'error'});
        });
    }

    return (
        <div>
            <Typography variant={"caption"} sx={{paddingBottom: "20px"}}>Отчет
                за {formatMonth(filterValues.period)}
                &nbsp;
                <Button variant={"outlined"} size="small" onClick={recalcParty} label="Пересчитать"><ReplayIcon
                    size="small"/></Button>
                <ExportExcelButton style={{float: "right"}}
                                   variant={"outlined"}
                                   resourceName="partyStatMonthly" exportFilename="Статистика_контрагента.xlsx"
                                   filterValues={filterValues}
                                   size="small"
                />
            </Typography>
            <ListContextProvider value={{
                data: data || [], total: total || 0,
                page: page, perPage: perPage, setPage,
                filterValues: filterValues,
                setFilters: setFilters,
                sort
            }}>
                <>
                    {showFilter && <PartyStatMonthlyFilter/>}

                    <DatagridWithFooter bulkActionButtons={false} rowStyle={rowstyle}
                                        footer={<PartyStatFooter colSpan={10} totalColumn="totalPriceRub"/>}>
                        <TextField source="id" label="ID" sortBy="id"/>
                        <TextField source="operationType.id" label="ID операции" textAlign="center"
                                   sx={{display: "block", width: 80}}/>
                        <TextField source="operationType.name" label="Операция"/>
                        <TextField source="operationType.portalName" label=""/>
                        <TextField source="total" label="Всего" textAlign="right"/>
                        <TextField source="success" label="Успешных" textAlign="right"/>
                        <TextField source="error" label="Ошибок" textAlign="right"/>
                        <TextField source="notFound" label="Не найдено" textAlign="right"/>
                        <FunctionField sortBy="price" textAlign="right" label="Цена,руб."
                                       render={record => formatMoney(record.priceRub)}/>
                        <FunctionField sortBy="totalPrice" label="Сумма,руб." textAlign="right"
                                       render={record => formatMoney(record.totalPriceRub)}/>

                    </DatagridWithFooter>
                    <Pagination/>
                </>
            </ListContextProvider>
        </div>
    )
}
PartyStatMonthlyList.propTypes = {
    partyId: PropTypes.number.isRequired,
    period: PropTypes.string,
    showFilter: PropTypes.bool
}

