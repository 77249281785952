import React from 'react';
//import { Filter } from 'react-admin';
import Filter from '../../components/filter'
import {DateInput, ReferenceInput} from 'react-admin';
import PartyAutoComplete from '../party/party-autocomplete';
import {connect} from 'react-redux';
import Button from '@mui/material/Button';
import {withStyles} from '@mui/styles';

const styles = theme => ({
})

class OperationFilter extends React.Component {
  childs = {};

  constructor(props) {
    super(props);
    const { filterValues } = this.props;

    this.state = { "createMoment_gte": filterValues.createMoment_gte, "createMoment_lte": filterValues.createMoment_lte }
  }

  setFilterValue = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  }

  checkEmptyFilter = (fieldName, event) => {
    //console.log("checkEmpty", fieldName, event);
    if (event.key === 'Enter' && !event.target.value) {
      this.setFilterValue(fieldName, null);
      if (this.props.setFilters) {
        const fv = { ...this.props.filterValues };
        fv[fieldName] = null;
      }
    }
  }

  hidePartyFilter() {
    let fv = { ...this.props.filterValues };
    delete fv.relyingParty.id;

    if (this.props.hideFilter) {
      this.props.hideFilter('relyingParty.id');
      this.childs["autoComplete"].setState({value: ""})
    }
  }

  handleSearch(event) {
    const { setFilters } = this.props;
    setFilters({ ...this.state });
  }

  render() { 
    const { classes } = this.props;

    return (
      <Filter { ...this.props } hideFilter={ () => this.hidePartyFilter() } >
        <DateInput alwaysOn source="createMoment_gte" label="Дата c:"  onChange={ (e, v) => this.setFilterValue("createMoment_gte", v) } />
        <DateInput alwaysOn source="createMoment_lte" label="Дата по:"  onChange={ (e, v) => this.setFilterValue("createMoment_lte", v) }/>

        <ReferenceInput alwaysOn source="relyingParty" label="Контрагент" reference="party"
            filterToQuery={ searchText => ({ name: searchText })}>

          <PartyAutoComplete onSelect={ (party) => this.setFilterValue('relyingParty.id', party.id) } style={{ marginTop: '20px' }}
            onKeyPress={ event => this.checkEmptyFilter('relyingParty.id', event) } myRefs={ this.childs }
          />
        </ReferenceInput>

        <Button alwaysOn variant="raised" color="primary" source="btnSearch"  onClick={ e => this.handleSearch(e)} style={{marginBottom: '10px', textTransform: 'none'}}>
          Отфильтровать
        </Button>
      </Filter> 
    )
  }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(OperationFilter));