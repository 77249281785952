import React from 'react';
import moment from "moment-timezone";
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import OperationTypeLookup from "../components/OperationTypeLookup";
import BaseListActions from "../components/BaseListActions";
import {withTransaction} from "@elastic/apm-rum-react";


const OperationStatFilter = (props) => {
    return (

        <Filter {...props} >
            <DateInput alwaysOn source="moment_gte" label="Дата c:" sx={{width: "170px"}}/>
            <DateInput alwaysOn source="moment_lte" label="Дата по:" sx={{width: "170px"}}/>


            <SelectInput label="Мелкочек" source="party.billingEnabled"
                         choices={[
                             {id: 'true', name: 'Да'},
                             {id: 'false', name: 'Нет'},
                         ]}
                         sx={{minWidth: "120px", width: "120px", marginBottom: "0px"}}
                         alwaysOn translateChoice={false}
                         InputLabelProps={{shrink: true}}

            />

            <OperationTypeLookup alwaysOn/>

            <TextInput label="Контрагент" source="party.name_like" alwaysOn
                       sx={{minWidth: "240px", width: "240px"}}
            />

        </Filter>
    )
}

// const ExportExcel = () => {
//     const {filterValues,sort} = useListContext();
//     const notify = useNotify();
//     const exportExcelClick=() => {
//
//         const params = {
//             filter: filterValues,
//             range: [0, 0],
//             sort: ['moment', 'DESC'],
//             excel: true
//         }
//         //const url = new URL(`${getBasePath()}/operation-export-rep1?${jsonToParams(params)}`);
//         const url = new URL(`${getBasePath()}/operationStat?${jsonToParams(params)}`);
//         const token = localStorage.getItem('token');
//
//         console.log("befor fetch");
//         fetch(url, {
//             method: 'GET',
//             headers: {
//                 'authorization': `Bearer ${token}`,
//                 'content-type': 'application/json',
//                 'accept': '*/*'
//             }
//         })
//             .then(response => {
//                 if (!response.ok) {
//                     notify(`HTTP ${response.status} - ${response.statusText}`, {type: "error"});
//                 }
//             })
//             .then(
//                 response => response.blob()
//             )
//             .then(blob => {
//                 var url = window.URL.createObjectURL(blob);
//                 var a = document.createElement('a');
//                 a.href = url;
//                 a.download = "Отчет_по_операциям.xlsx";
//                 document.body.appendChild(a);
//                 a.click();
//                 a.remove();
//             })
//             .catch(err => {
//                 notify(err, {type: "error"});
//             });
//
//         const checkStatus = (response) => {
//             if (!response.ok) {
//                 notify(`HTTP ${response.status} - ${response.statusText}`, {type: "error"});
//             }
//             return response;
//         }
//     }
//
//     return(
//         <Button color="primary" onClick={exportExcelClick}>Export_Excel</Button>
//     )
// }
//
//

const OperationStatList = (props) => {
    // const notify = useNotify();
    // const {filterValues} = useListContext();

    let d = new Date()
    const startDate = (d.getDate() == 1) ?
        moment(d).subtract(1, 'months').startOf('month').format("YYYY-MM-DD") :
        moment(d).startOf('month').format("YYYY-MM-DD");
    const stopDate = moment(d).format("YYYY-MM-DD");

    return (
        <List title="Статистика по операциям (new)"
              filters={<OperationStatFilter/>}
              actions={<BaseListActions name="operationStat" exportFilename="operationStat.xlsx"/>}
              // actions={    <TopToolbar>
              //     <ExportExcel/>
              // </TopToolbar>
              // }
                disableSyncWithLocation={true}
              perPage={50}
              sort={{field: 'moment', order: 'DESC'}}
              filterDefaultValues={{moment_gte: startDate, moment_lte: stopDate}}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id"/>
                <DateField source="moment" label="Дата"/>
                <TextField source="party.name" label="Контрагент" />
                <BooleanField source="party.billingEnabled" label="Мелкочек" align="center" />
                <TextField source="operationType.id" label="Операция(id)"/>
                <TextField source="operationType.name" label="Операция"/>
                <TextField source="operationType.comment" label="Источник"/>
                <TextField source="operationType.displayName" label="Описание"/>
                <NumberField source="total" label="Всего"/>
                <NumberField source="success" label="Успешно"/>
                <NumberField source="error" label="Ошибок"/>
                <NumberField source="notFound" label="Не найдeно"/>
            </Datagrid>
        </List>
    )

}


export default withTransaction('OperationStatList','component')(OperationStatList);