import React from 'react';
import {
    BulkDeleteButton,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import {withStyles} from "@mui/styles";
import BaseListActions from "../components/BaseListActions";
import {withTransaction} from "@elastic/apm-rum-react";


const styles = theme => ({

    name: {
        width: '50%'
    },

})

const TrustPersonBaseFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Название базы" source="name_like" alwaysOn  style={{width: "300px"}}
                   InputLabelProps={{shrink: true}}
                   InputProps={{style: {fontSize: "small"}}}
        />
        <ReferenceInput  {...props} label="Категория" source="trustPersonCategoryId" reference="trustPersonCategory"
                          alwaysOn
                         perPage={1000} sort={{field: "id", order: "ASC"}}
                         translateChoice={false} style={{width: "300px"}}
                         InputLabelProps={{shrink: true,}}
        >
            <SelectInput optionText="name" optionValue="id"/>
        </ReferenceInput>

    </Filter>
);

const MyBulkActionButtons = props => (
    <>
        <BulkDeleteButton
            undoable={false}
            label="Удалить" {...props}
            confirmTitle="Удаление"
            confirmContent="Вы уверены, что хотите удалить выбранные записи?"
        />

    </>
);


export const TrustPersonBaseList = withStyles(styles)(withTransaction('TrustPersonBaseList','component')(props => {
    const {classes, basePath, resource, selectedIds} = props;
    console.log("selectedIds", selectedIds);
    return (
        <List {...props} title={"Настройка скоринга благонадежности. Категории и базы данных"}
              sort={{field: 'name', order: 'ASC'}}
              perPage={100}
              actions={
                  <BaseListActions name="trustPersonBase" exportFilename={"trustPersonBaseList.xlsx"}>
                      <CreateButton label="Добавить базу" basePath="trustPersonBase"/>
                  </BaseListActions>
              }
              bulkActionButtons={<MyBulkActionButtons/>}
              filters={<TrustPersonBaseFilter/>}
        >
            <Datagrid rowClick="edit">
                <TextField source="trustPersonBase.id" label="ID" sortBy="id"/>
                <TextField source="trustPersonCategory.name" label="Категория" sortBy="trustPersonCategoryId"/>
                <TextField source="trustPersonBase.name" label="База" sortBy="name"/>
            </Datagrid>

        </List>
    )
}));
