import withStyles from '@mui/styles/withStyles';
import {
    BooleanInput,
    Edit,
    EditController,
    ListButton,
    ReferenceInput,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar, usePermissions,
    useRecordContext,
} from 'react-admin';
import {useLocation, useNavigate} from "react-router-dom";
import PartyInPriceListList from "./PartyInPriceListList";
import OperationTypePriceEdit from "./OperationTypePriceEdit";
import roles from "../constants/roles";


const styles = theme => ({
    name: {maxWidth: 600},
    comment: {minWidth: "600",}
})

const required = value => value != null ? undefined : 'Required';

const PriceListEditToolbar = (props => {
    const location = useLocation();
    const navigate = useNavigate();

    const record = useRecordContext();
    const returnUrl="/service-spec/"+record.serviceSpecId+"/2";

    const onSuccess = () => {
        navigate(returnUrl);
    }

    return (
        <Toolbar {...props}  >
            {location.pathname.match(/(priceList\/\d+$)/g) &&
                <SaveButton type="button" mutationOptions={{onSuccess: onSuccess}}/>
            }
                <ListButton label="Назад" icon={null}
                        onClick={(e) => {
                            e.preventDefault();
                            onSuccess();
                        }}/>
        </Toolbar>
    )
});

const TextFieldInForm = ({variant, ...props}) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

const PriceListTitle=()=>{
    const record = useRecordContext();
    if (!record) return null;
    return(
        <span>Прайслист id={record.id}, {record.name}</span>
    );
}

const PriceListEdit = (props) => {
    const {classes} = props;
    const {permissions} = usePermissions();

    return (
        <Edit mutationMode="pessimistic" title={<PriceListTitle/>} >
            <EditController {...props} >
                {controllerProps => {
                    // console.log("controllerProps", controllerProps);
                    return (
                        <TabbedForm {...controllerProps}
                                    toolbar={<PriceListEditToolbar/>}
                                    submitOnEnter={false}
                        >

                            <TabbedForm.Tab label="Атрибуты" variant="outlined">

                                <TextInput source="id" inputProps={{readOnly: true,}}/>

                                <ReferenceInput reference="service-spec"  source="serviceSpecId">
                                    <SelectInput optionText="name" optionValue="id"
                                                 label="Тарифный план"
                                                 sx={{width:"50%"}}
                                                 inputProps={{readOnly: true,}}/>
                                </ReferenceInput>


                                <TextInput source="name" label="Наименование" multiline validate={required}
                                           formClassName={classes.name}
                                           sx={{width: "60%"}}
                                           inputProps={{readOnly: permissions.includes(roles.melkocheck_admin)?false:true,}}
                                />

                                <BooleanInput label="Прайслист по умолчанию" source="isDefault"
                                              inputProps={{disabled: true,}}
                                />

                            </TabbedForm.Tab>

                            <TabbedForm.Tab label="Цены">
                                <OperationTypePriceEdit priceListId={controllerProps.record.id} />
                            </TabbedForm.Tab>


                            {
                                !controllerProps.record.isDefault &&
                                <TabbedForm.Tab label="Контрагенты">
                                    <PartyInPriceListList/>
                                </TabbedForm.Tab>
                            }

                        </TabbedForm>
                    )
                }
                }
            </EditController>
        </Edit>
    );
}

export default withStyles(styles)(PriceListEdit);