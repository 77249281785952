import {Create, ReferenceInput, SelectInput, SimpleForm, TextInput,} from 'react-admin';


const PriceListCreate = () => {
    return (
        <Create title={"Новый прайслист" } resource="priceList">
            <SimpleForm>
                <ReferenceInput reference="service-spec"  source="serviceSpecId">
                    <SelectInput optionText="name" optionValue="id"
                                 label="Тарифный план"
                                 sx={{width:"50%"}}
                                 inputProps={{readOnly: true,}}/>
                </ReferenceInput>

                <TextInput  source="name" label="Название" sx={{width:"50%"}}/>
            </SimpleForm>
        </Create>
    );
}

export default PriceListCreate;