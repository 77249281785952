import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {Puff} from 'react-loader-spinner'
import {useLoading} from "react-admin";


const LoadingPopup = (props) => {

    let timeoutID = useRef(null);
    const isLoading = useLoading();
    const [indicator, setIndicator] = useState(false);

    useEffect(() => {
        const ensureTimer = () => {
            // console.log("isLoading=", isLoading, "timeoutID=",timeoutID);
            if (isLoading) {

                if (!timeoutID.current) {
                    timeoutID.current = setTimeout(() => {
                        timeoutID.current = null;
                        setIndicator(true);
                    }, 400);
                }
            } else {
                destroyTimer();
            }
        }

        const destroyTimer = () => {
            clearTimeout(timeoutID.current);
            timeoutID.current = null;
            setIndicator(false);
        }

        // componentwillmount in functional component.
        // Anything in here is fired on component mount.
        ensureTimer();
        return () => {
            // componentwillunmount in functional component.
            // Anything in here is fired on component unmount.
            destroyTimer();
        }
    }, [isLoading]);

    return (
        <>
            <Dialog open={indicator} >
                <DialogContent sx={{width: '330px', height: '170px', textAlign: 'center'}} >
                    <Puff color="green" height={90} width={90} wrapperStyle={{justifyContent:"center"}} />
                    <DialogContentText style={{marginTop: '20px', fontWeight: 'bold'}}>
                        Выполняется запрос данных...
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default LoadingPopup;
