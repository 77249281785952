import React from 'react';
import {Datagrid, DateField, EditButton, Filter, FunctionField, List, TextField, TextInput,} from 'react-admin';
import BoolNumField from 'components/bool-num-field';
import {withTransaction} from "@elastic/apm-rum-react";

const ServiceSpecFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn/>
    </Filter>
);

const ServiceSpecList = props => (
    <List {...props}
          actions={<></>}
          title=" Список тарифных планов" filters={<ServiceSpecFilter/>} perPage={50}>

        <Datagrid
            /*expand={<><PriceListList editablePrices={false}/></>} */
                  bulkActionButtons={false}
        rowClick="edit"
        >
            <TextField source="id"/>
            <TextField source="tenant" label="Тарифная группа" />
            <TextField source="name" label="Название"/>
            <TextField source="serviceSpecType" label="Тип"/>
            <TextField source="comment" label="Описание"/>
            <FunctionField source="price" label="Стоимость тарифа, руб."
                           render={record => `${record.price / 100}`}/>
            <TextField source="period" label="Период действия"/>
            <TextField source="operationLimit" label="Лимит по количеству / стоимости операций"/>

            <DateField source="startMoment" label="Дата начала действия тарифа"/>
            <DateField source="stopMoment" label="Дата окончания действия тарифа"/>

            <BoolNumField source="active" label="Активен"/>
            {/*<BoolNumField source="permitForSelect" label="Доступен для выбора контрагентами"/>*/}
            {/*<BoolNumField source="canStopInstantly" label="Автозакрытие при смене тарифного плана"/>*/}
            {/*<BoolNumField source="allowMultiple"*/}
            {/*              label="Аккаунт может иметь несколько активных подписок на данный тариф"/>*/}
            {/*<BoolNumField source="allowMultipleMixed"*/}
            {/*              label="Аккаунт может иметь несколько активных подписок на разные тарифы"/>*/}

            <EditButton label="Изменить"/>
        </Datagrid>
    </List>
);

export default withTransaction('ServiceSpecList','component')(ServiceSpecList);