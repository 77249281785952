import moment from "moment-timezone";

const getBasePath = () => {
    // console.log(process.env.REACT_APP_BASE_URL)
    if (process.env.REACT_APP_BASE_URL.startsWith("/")) {
        return `${window.location.origin}${process.env.REACT_APP_BASE_URL}`;
    } else {
        return process.env.REACT_APP_BASE_URL;
    }
}

const jsonToParams = (json) => {
    let result = "";

    for (var key in json) {
        result += `${key}=${encodeURIComponent(JSON.stringify(json[key]))}&`
    }
    ;

    return result.length > 1 ? result.substring(0, result.length - 1) : result;
}

const formatDatetime = (date, fmt) => {
    let val = "";
    if (date) {
        val = moment(date).format(fmt ? fmt : 'DD.MM.YYYY hh:mm:ss');
    }
    return val;
}


const formatDate = (date, fmt) => {
    return formatDatetime(date, fmt ? fmt : 'DD.MM.YYYY');
}

export const formatMoney =(value) => {
    return Number(value? value: 0)
        .toLocaleString('en', {maximumFractionDigits:2,minimumFractionDigits:2});
}

export {getBasePath, jsonToParams, formatDate, formatDatetime};