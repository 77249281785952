import React from 'react';
import PropTypes from 'prop-types';
import HelpIcon from '@mui/icons-material/Help';
import PeopleIcon from '@mui/icons-material/People';
import MonetizationIcon from '@mui/icons-material/MonetizationOn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FunctionsIcon from '@mui/icons-material/Functions';

import {useLocation, useNavigate, useParams} from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import {Menu, MenuItemLink, usePermissions, useSidebarState} from 'react-admin';
import SubMenu from './SubMenu';
import {
    Brightness5,
    Category,
    ChevronRight,
    Discount,
    Face,
    Group,
    List,
    PictureInPicture,
    PlaylistAddCheck,
    Search,
    Settings,
    ShoppingCart
} from '@mui/icons-material';
import Roles from "../constants/roles";

const styles = (theme) => ({
    menuItemLink: {
        padding: "5px",
        '& span': {
            paddingRight: '5px',
            height: '20px'
        },
        '& div': {
            minWidth: "32px",
            maxWidth: "32px"
        }

    },

});


const IdxMenu = (props) => {
    // const state = {
    //     menuCatalog: false,
    //     menuSales: false,
    //     menuCustomers: false,
    //     menuReports: true,
    //     menuSources: true,
    //     menuOper: true,
    //     menuMelko: true,
    //     menuOptions: false,
    // };
    const {permissions} = usePermissions();

    const handleToggle = menu => {
        this.setState(state => ({[menu]: !state[menu]}));
    };

    const {onMenuClick, logout, classes} = props;


    const [open] = useSidebarState();

    return (
        <Menu>
            <MenuItemLink to={`/dashboard`} onClick={onMenuClick} className={classes.menuItemLink}
                          primaryText={"Dashboard"}
                          leftIcon={<DashboardIcon/>}
            />

            <Menu.Item to={{pathname:'/party', search:"sort=id&order=DESC"}} onClick={onMenuClick} className={classes.menuItemLink}
                          primaryText={"Все контрагенты"}
                          leftIcon={<Group/>}
            />
            {
                permissions.includes(Roles.party_admin) &&
                <Menu.Item to={{pathname: '/partyStatMonthly/partyList'}} onClick={onMenuClick}
                           className={classes.menuItemLink}
                           primaryText={"Отчеты"}
                           leftIcon={<Group/>}
                />
            }

            <SubMenu handleToggle={() => handleToggle('menuOperations')}
                     isOpen={true} /*{this.state.menuOper}*/
                     sidebarIsOpen={open}
                     name="Операции"
                     icon={<ShoppingCart/>}
            >
                <MenuItemLink to={`/operation`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Все операции"}
                              leftIcon={<ShoppingCart/>}
                />
                <MenuItemLink to={`/operationToken`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Поиск по токену"}
                              leftIcon={<Search/>}
                />
            </SubMenu>

            <SubMenu handleToggle={() => this.handleToggle('melko')}
                     isOpen={true} /*{this.state.menuMelko}*/
                     sidebarIsOpen={open}
                     name="Мелкочек"
                     icon={<PeopleIcon/>}
            >
                <MenuItemLink to={{pathname:'/melko/party', search:'sort=id&order=DESC'}} onClick={onMenuClick}
                              className={classes.menuItemLink}
                              primaryText="Контрагенты"
                              leftIcon={<Face/>}
                />
                <MenuItemLink to={{pathname:'/melko/payment', search:'sort=id&order=DESC'}} onClick={onMenuClick}
                              className={classes.menuItemLink}
                              primaryText={"Платежи"}
                              leftIcon={<MonetizationIcon/>}
                />
                <MenuItemLink to={{pathname:'/melko/paymentRequest', search:'sort=id&order=DESC'}} onClick={onMenuClick}
                              className={classes.menuItemLink}
                              primaryText={"Запрос пополнения"}
                              leftIcon={<MonetizationIcon/>}
                />

{/*
                <MenuItemLink to={`/faq`} primaryText={"FAQ"} onClick={onMenuClick} className={classes.menuItemLink}
                              leftIcon={<HelpIcon/>}
                />
*/}

                <MenuItemLink to={`/service-spec`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Тарифные планы"}
                              leftIcon={<PictureInPicture/>}
                />
                <MenuItemLink to={{pathname:`/melko/promocode`}} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Промокоды"}
                              leftIcon={<Discount/>}
                />
                <MenuItemLink to={`/melkoStat`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Статистика (устаревш)"}
                              leftIcon={<PictureInPicture/>}
                />
            </SubMenu>

            <SubMenu handleToggle={() => this.handleToggle('menuReports')}
                     isOpen={true} /*{this.state.menuReports}*/
                     sidebarIsOpen={open}
                     name="Отчеты"
                     icon={<ChevronRight/>}
            >
                <MenuItemLink to={`/operationStat/`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"По операциям"}
                              leftIcon={<PlaylistAddCheck/>}
                />
                <MenuItemLink to={`/operationStatbyType`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"По операциям(сум)"}
                              leftIcon={<FunctionsIcon/>}
                />
                <MenuItemLink to={`/operationStatbyParty`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"По контрагентам(сум)"}
                              leftIcon={<FunctionsIcon/>}
                />
                {/*
                    <MenuItemLink to={`/nbki-report`} onClick={onMenuClick} className={classes.menuItemLink}
                                  primaryText={"НБКИ"}
                                  leftIcon={<List/>}
                    />
*/}
            </SubMenu>
            <SubMenu handleToggle={() => this.handleToggle('menuSources')}
                     isOpen={true} /*{this.state.menuSources}*/
                     sidebarIsOpen={open}
                     name="Источники"
                     icon={<ChevronRight/>}
            >

                <MenuItemLink to={`/operationLogStatNbki`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"НБКИ"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatOkb`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"ОКБ"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatRscb`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"КБ Русский стандарт"}
                              leftIcon={<List/>}
                />

                <MenuItemLink to={`/operationLogStatFns`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"ФНС"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatIds`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"ID-Solutions"}
                              leftIcon={<List/>}
                />


                <MenuItemLink to={`/operationLogStatEsia`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"ЕСИА"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatparserApi`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Парсер API"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatdbrain`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Dbrain"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatosnovanie`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Основание"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatsmev`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"СМЭВ.IDX"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatSinara`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Синара"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatSolaris`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Солярис"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatIdx`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"IDX"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatIdxData`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"IDX-Data"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatReputation`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Репутация"}
                              leftIcon={<List/>}
                />
                <MenuItemLink to={`/operationLogStatBasis`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Базис"}
                              leftIcon={<List/>}
                />

                <MenuItemLink to={`/solarisStat`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Солярис.Cтат"}
                              leftIcon={<Brightness5/>}
                />



            </SubMenu>

            <SubMenu handleToggle={() => this.handleToggle('menuOptions')}
                     isOpen={true} /*{this.state.menuOptions}*/
                     sidebarIsOpen={open}
                     name="Настройки"
                     icon={<Settings/>}
            >
                <MenuItemLink to={`/trustPersonBase`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Скор благонадежности"}
                              leftIcon={<Brightness5/>}
                />
                <MenuItemLink to={`/operationType`} onClick={onMenuClick} className={classes.menuItemLink}
                              primaryText={"Тип операции"}
                              leftIcon={<Category/>}
                />
            </SubMenu>

            {logout}
        </Menu>
    );

}

IdxMenu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};


function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props} location={location}
                router={{location, navigate, params}}
            />
        );
    }

    return ComponentWithRouterProp;
}

// const enhance = compose(withRouter, connect({}, {}));

export default withRouter(withStyles(styles)(IdxMenu));
