import React from "react";
import {Datagrid, DateField, Form, List, TextField, TextInput, useListContext} from 'react-admin';
import {Box, Button} from '@mui/material';
import {withTransaction} from "@elastic/apm-rum-react";

const OperationTokenFilter = props => {
    const {
        showFilter,
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter,
    } = useListContext();

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        }
    };

    return (
        props.context === "button" ? (
            null
        ) : (
            <Form onSubmit={onSubmit} initialValues={filterValues} style={{display: "flex"}}>
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit} style={{display: "flex", width: "90%"}}>

                        <TextInput multiline
                                   alwaysOn
                                   style={{width: "90%"}}
                                   variant="outlined"
                                   resettable
                                   helperText={false}
                                   source="operationNumber"
                                   label="Токены, csv-список"
                                   InputProps={{style: {fontSize: "small"}}}
                        />

                        <Box component="span" mb={1} mt={1}>
                            <Button variant="contained" color="primary" type="submit">
                                ПОИСК
                            </Button>
                        </Box>

                    </form>
                )}
            </Form>
        )
    );
};


const OperationTokenList = (props) => {
    // const dataProvider = useDataProvider();
    // const notify=useNotify();
    // const search = (token) => {
    //     console.log("token->", token);
    //     dataProvider
    //         .getList('operation/token', {pagination: {page:1, perPage:1000}, sort:{}, filter: {operationNumber: token}} )
    //         .then( (response) => {
    //             // success
    //             console.log("response", response);
    //
    //         })
    //         .catch((error) => {
    //             // failure side effects go here
    //             console.log("error->", error);
    //             notify(`Comment approval error: ${error.message}`, 'error');
    //         })
    // };
    //
    //
    // const handleSubmit = (values) => {
    //     console.log("values->", values);
    //     search(values.token);
    // }


    return (
        <List {...props} filters={<OperationTokenFilter/>} actions={null} bulkActionButtons={false}
              perPage={50}
              sort={{field: 'id', order: 'DESC'}}
            empty={false}
        >
                    <Datagrid
                        /*expand={<OperationAttributeList/>}*/
                    >
                        <TextField source="id"/>
                        <TextField source="operationNumber" label="Токен"/>
                        <TextField source="operationType.name" label="Тип операции"/>
                        <TextField source="operationState.name" label="Результат"/>
                        <TextField source="relyingParty.name" label="Контрагент"/>
                        <TextField source="serviceSupplier.name" label=" Поставщик"/>
                        <DateField source="createMoment" label="Момент старта" showTime/>
                        <DateField source="modifyMoment" label="Момент завершения" showTime/>
                    </Datagrid>
        </List>
    )

}

export default withTransaction('OperationTokenList', 'component')(OperationTokenList);