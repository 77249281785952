import React, {useState} from 'react';
import {
    BooleanInput,
    Button,
    Form,
    required,
    SaveButton,
    TextInput,
    useDataProvider,
    useLoading,
    useNotify
} from 'react-admin';
import {RotateLeft} from '@mui/icons-material';
import IconCancel from '@mui/icons-material/Cancel';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from "prop-types";

function PartyResetPasswordButton({onChange, buttonStyle}) {
    const [showResetDialog, setShowResetDialog] = useState(false);
    const dataProvider = useDataProvider();
    const isLoading = useLoading();
    const notify = useNotify();

    const handleClick = () => {
        setShowResetDialog(true);
    };
    const handleCloseClick = () => {
        setShowResetDialog(false);
    };
    const handleSubmit = async values => {
        dataProvider.customUpdate("party/credentials",
            {
                id: values.id,
                data: {
                    resetKeys: values.resetKeys,
                    resetPassword: values.resetPassword,
                    email: values.email
                }
            }).then((response) => {
                setShowResetDialog(false);
                notify("Реквизиты доступа успешно изменены, на указанный email отправлены новые реквизиты доступа");
                if (onChange) {
                    onChange();
                }
                console.log("response", response);
            }).catch((error) => {
                    notify("Ошибка при обновлении доступов контрагента: " + error, {type: 'error'});
            });
    };

    return (
        <>
            <Button color="primary" onClick={handleClick} label="Сбросить пароль" style={buttonStyle}>
                <RotateLeft/>
            </Button>

            <Dialog
                fullWidth
                // maxWidth="sm"
                open={showResetDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Сброс реквизитов доступа</DialogTitle>
                <Form resource="party" onSubmit={handleSubmit}>
                    <DialogContent>
                        <BooleanInput label="Сброс пароля" source="resetPassword" defaultValue={false}/>
                        <BooleanInput label="Сброс ключей доступа" source="resetKeys" defaultValue={false}/>
                        <TextInput source="email" label="Электронная почта контрагента"
                                   validate={required()} variant="outlined"
                                   fullWidth
                        />
                        <Typography variant={"body2"}>
                            Новые реквизиты доступа будут отправлены на указанный адрес электронной почты.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button label="Отмена"
                                onClick={handleCloseClick}
                                disabled={isLoading}
                        >
                            <IconCancel/>
                        </Button>
                        <SaveButton
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    );

}

PartyResetPasswordButton.propTypes = {
    onChange: PropTypes.func,
    buttonStyle: PropTypes.object}

export default PartyResetPasswordButton;
