import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

class AlertDialog extends React.Component {
    state = {
        alertDialogOpen: false
    };

    constructor() {
        super();

    }

    handleClickOpen = () => {
        this.setState({ alertDialogOpen: true });
    };

    handleClose = () => {
        this.setState({ alertDialogOpen: false });
    };

    handleConfirm = () => {
        //console.log("I agree!");
        if (this.props.onConfirm) {
            this.props.onConfirm();
        }
        this.handleClose();
    };
    handleCancel = () => {
        //console.log("I do not agree.");
        this.handleClose();
    };
    render() {
        return (
            <div>
                {/* Dialog that is displayed if the state open is true */}
                <Dialog open={this.state.alertDialogOpen} onClose={this.handleClose}>
                    <DialogTitle> {this.props.title} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {this.props.description}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="primary">
                            Отмена
                        </Button>
                        <Button onClick={this.handleConfirm} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AlertDialog.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string,
    onConfirm: PropTypes.func
};


export default AlertDialog;
