import {Button, Datagrid, DateField, List, TextField,} from "react-admin";
import {PartyFilter} from "./party-filter";
import BoolNumField from "../../components/bool-num-field";
import PropTypes from "prop-types";
import IconContentAdd from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconCancel from "@mui/icons-material/Cancel";
import {useState} from "react";


export const PartyLookupButton = props => {
    const [showPartyLookup, setShowPartyLookup] = useState(false);

    const{buttonLabel, onPartySelect} = props;

    const handlePartySelect = id => {
        onPartySelect(id);
        setShowPartyLookup(false);
    }

    return (
        <>
            <Button color="primary"
                    label={buttonLabel}
                    sx={{
                        // float: "right",
                        margin: "15px",
                    }}
                    onClick={()=> {setShowPartyLookup(true);}}
            >
                <IconContentAdd/>
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={showPartyLookup}
                onClose={() => setShowPartyLookup(false)}
            >
                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <PartyLookup onSelect={handlePartySelect}/>
                </DialogContent>
                <DialogActions>
                    <Button label="Закрыть"
                            onClick={()=>setShowPartyLookup(false)}
                            disabled={false}>
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

PartyLookupButton.propTypes={
    buttonLabel:PropTypes.string.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    onPartySelect: PropTypes.func.isRequired,
}

const PartyLookup = (props) => {
    const {onSelect} = props;

    const handleRowClick=(id,resource,record) => {
        // console.log("handleRowClick", id, resource,record);
        return onSelect(id);
    }

    return (

        <List title="Список контрагентов" resource="party" perPage={25}
              filters={<PartyFilter/>}
        >
            <Datagrid {...props} bulkActionButtons={false}
                      rowClick={(id, resource, record)=> {return handleRowClick(id,resource,record);}}
            >
                <TextField source="id"/>
                <TextField source="username" label="Email/Логин"/>
                <TextField source="name" label="Название"/>
                <TextField source="inn" label="ИНН"/>

                <TextField source="partyType" label="Тип"/>
                <BoolNumField source="isLegal" label="Юр.лицо"/>
                <BoolNumField source="emailConfirmed" label="Email подтвержден"/>
                <BoolNumField source="active" label="Активен"/>
                <BoolNumField source="billingEnabled" label="Мелкочек"/>
                <DateField source="createMoment" label="Дата регистрации"/>
            </Datagrid>
        </List>
    )
};
PartyLookup.propTypes={
    onSelect: PropTypes.func.isRequired,
}
