import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MelkoStatByParty from "./MelkoStatByParty";
import MelkoStatByService from "./MelkoStatByService";
import MelkoStatByOper from "./MelkoStatByOper";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const MelkoStat = props => {
    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Paper square>
            <Tabs value={tabValue} onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered >
                <Tab label="По мелкочекам"/>
                <Tab label="По подпискам" />
                <Tab label="По запросам" />
            </Tabs>

            <TabPanel value={tabValue} index={0} >
                <MelkoStatByParty {...props}/>
            </TabPanel>

            <TabPanel value={tabValue} index={1} >
                <MelkoStatByService {...props}/>
            </TabPanel>

            <TabPanel value={tabValue} index={2} >
                <MelkoStatByOper {...props}/>
            </TabPanel>
        </Paper>
    )
}

export default MelkoStat;