import React, {useState} from 'react';
import {Button, Form, required, SaveButton, SelectInput, TextInput, useCreate, useNotify} from 'react-admin';
import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from "prop-types";

function PartyCreateButton({onChange}) {
    const [showDialog, setShowDialog] = useState(false);
    const [create, {isLoading, error}] = useCreate();
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };
    const handleCloseClick = () => {
        setShowDialog(false);
    };
    const handleSubmit = async values => {
        create(
            'party',
            {data: values},
            {
                onSuccess: ({data}) => {
                    setShowDialog(false);
                    notify("Новый контрагент успешно создан, на указанный email отправлены реквизиты доступа");
                    if (onChange) {
                        onChange();
                    }
                },
                onError: (error => {
                    notify("Ошибка при создании контрагента: " + (error && error.message ? error.message : ""),
                        {type: 'error',undoable: false, autoHideDuration: 10000});
                })
            }
        );
    };

    return (
        <>
            <Button color="primary" onClick={handleClick} label="Новый контрагент">
                <IconContentAdd/>
            </Button>

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Новый контрагент</DialogTitle>
                <Form resource="party" onSubmit={handleSubmit}>
                    <>
                        <DialogContent>
                            <TextInput source="name" label="Название контрагента (латиницей)"
                                       validate={required()}
                                       fullWidth
                            />
                            <TextInput source="inn" label="ИНН контрагента"
                                       validate={required()}
                            />
                            <br/>
                            <SelectInput label="Права доступа" source="permission" choices={[
                                {id: 'defaultPerm', name: 'Стандартный доступ'},
                                {id: 'noPerm', name: 'Без прав доступа'},
                            ]} translateChoice={false}/>
                            <TextInput source="email" label="Электронная почта контрагента"
                                       validate={required()}
                                       fullWidth
                            />

                            <Typography variant={"body2"}>
                                После создания контрагента на указанный адрес электронной почты будет выслано письмо с
                                реквизитами доступа.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button label="Отмена"
                                    onClick={handleCloseClick}
                                    disabled={isLoading}>
                                <IconCancel/>
                            </Button>
                            <SaveButton disabled={isLoading}/>
                        </DialogActions>
                    </>
                </Form>
            </Dialog>
        </>
    );

}

PartyCreateButton.propTypes = {
    onChange: PropTypes.func
}

export default PartyCreateButton;
