import {Filter, SelectInput, TextInput} from "react-admin";

export const PartyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Название" source="name_like" alwaysOn/>
        <TextInput label="Логин" source="username_like" alwaysOn sx={{width: "100px"}}/>
        <TextInput label="Email" source="email_like" alwaysOn/>
        <TextInput label="ИНН" source="inn_like" alwaysOn sx={{width: "130px"}}/>

        <SelectInput label="Тип" source="partyType"
                     sx={{minWidth: "130px", width: "130px", marginBottom: 0}}
                     choices={[
                         {id: 'serviceProvider', name: 'Контрагенты'},
                         {id: 'idp', name: 'Поставщики'},
                     ]} alwaysOn/>

        <SelectInput label="Мелкочек" source="billingEnabled"
                     sx={{minWidth: "100px", width: "100px", marginBottom: 0}}
                     choices={[
                         {id: 'true', name: 'Да'},
                         {id: 'false', name: 'Нет'},
                     ]} alwaysOn/>

        <SelectInput label="ЛС IDX" source="isTest"
                     sx={{minWidth: "100px", width: "100px", marginBottom: "0px"}}
                     choices={[
                         {id: 'true', name: 'Да'},
                         {id: 'false', name: 'Нет'},
                     ]} alwaysOn/>
        {/*        <TextInput label="Телефон" source="phone"/>*/}
    </Filter>
);
