import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const Dashboard = (props) => {



    return (
      <Card>
          <CardHeader title="АРМ Администратора" />
          <CardContent>Выполнение администраторских функций по работе с контрагентами</CardContent>
      </Card>
    )
}

export default Dashboard;
