import React from 'react';
import {TopToolbar} from 'react-admin';
import PropTypes from "prop-types";
import ExportExcelButton from "./ExportExcelButton";


const BaseListActions = ({
                             name,
                             exportFilename,
                             // currentSort,
                             className,
                             // filterValues,
                            children,
                             ...rest
                         }) => {

      return (
        <TopToolbar className={className}>
            {children}
            <ExportExcelButton resourceName={name} exportFilename={exportFilename}  />
        </TopToolbar>

    );
}

BaseListActions.propTypes = {
    name: PropTypes.string,
    exportFilename: PropTypes.string,
    currentSort: PropTypes.object,
    className: PropTypes.string,
    filterValues: PropTypes.object,
}


export default (BaseListActions);