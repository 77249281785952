import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {NumberInput, SaveButton, SimpleForm, TextInput, Toolbar, useDataProvider, useNotify} from 'react-admin';

const styles = theme => ({})

const required = value => value != null ? undefined : 'Required';

const PartyInvoiceCreate = (props) => {

    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleSave = (partyPayment) => {

        const {id, onSuccess} = props;

        //На сервер надо передавать сумму в копейках
        const postData = {
            partyId:id,
            amount: partyPayment.amount * 100,
            comment: partyPayment.comment
        };

        dataProvider
            .create('partyInvoice', {data: postData})
            .then(response => {
                onSuccess();
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка добавления invoice`, 'error');
            });

    }

    return (
        <SimpleForm onSubmit={handleSave} toolbar={<Toolbar><SaveButton /></Toolbar>}>
            <NumberInput source="amount" label="Сумма (руб.)" validate={required}/>
            <TextInput source="comment" label="Комментарий"/>
        </SimpleForm>
    )
}
export default withStyles(styles)(PartyInvoiceCreate);
