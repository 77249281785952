import {
    FunctionField,
    List,
    TextField,
    Button, useNotify} from "react-admin";
import {Box} from "@mui/material";
import { formatMoney} from "../lib/utils";
import React, {Children, useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import {withTransaction} from "@elastic/apm-rum-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconCancel from "@mui/icons-material/Cancel";
import dataProvider from "../components/data-provider";
import {useQueryClient} from "react-query";
import ReplayIcon from '@mui/icons-material/Replay';
import {DatagridWithFooter} from "../components/DatagridWithFooter";
import {PartyStatMonthlyList} from "./PartyStatMonthlyList";
import {formatMonth, PartyStatFooter, PartyStatMonthlyFilter} from "./PartyStatMonthlyHelpers";




/**
 * Список контрагентов, для которых есть сформированные отчеты

 */
export const PartyStatMonthlyPartyList = withTransaction('Список финансовых отчетов',
    'component')((props) => {
    const [period, setPeriod] = useState(null);
    const notify = useNotify();
    const queryClient = useQueryClient();
    const recalcAll = () => {
        dataProvider.customUpdate("partyStatMonthly/recalcAll", {
            id: null,
            method: "POST",
            data: {
                period: period
            }
        }).then((response) => {
            queryClient.invalidateQueries({queryKey: ['partyStatMonthly/partyList']});
            notify("Отчет пересчитан");
        }).catch((error) => {
            notify("Ошибка: " + error, {type: 'error'});
        });
    }
    return (
        <>
            <Typography variant={"caption"} sx={{padding: "20px"}}>
                Список контрагентов, для которых сформированы отчеты за {formatMonth(period)} &nbsp;
                <Button variant={"outlined"} size="medium" onClick={recalcAll}><ReplayIcon/>Пересчитать</Button>
            </Typography>
            <List resource="partyStatMonthly/partyList" disableSyncWithLocation
                  title={"Отчеты контрагентов "}
                  filters={<PartyStatMonthlyFilter onFilterChange={values => setPeriod(values.period)}/>}
                  sort={false}
                  perPage={1000}
                  actions={false}
                  storeKey=""
                  empty={<Box sx={{padding: "1em"}}>Нет данных</Box>}
            >
                <DatagridWithFooter bulkActionButtons={false} footer={<PartyStatFooter colSpan={5} totalColumn="totalPrice"/>} >

                    <TextField source="id" label="ID" sortBy="id"/>
                    <TextField source="name" label="Контрагент" sortBy="name"/>
                    <TextField source="username" label="Aккаунт" sortBy="username"/>
                    <TextField source="inn" label="ИНН" sortBy="inn"/>
                    <TextField source="email" label="Контакты" sortBy="email"/>
                    <FunctionField label="Сумма,руб" sortBy="(totalPrice)" textAlign="right"
                                   render={record => formatMoney(record.totalPrice)}/>

                    <FunctionField render={record => (
                        <ReportButton period={period} partyId={record.id}
                                          dialogTitle={"Контрагент: " + record.name}
                                          buttonLabel="Отчет"
                        />
                    )}/>
                    <FunctionField render={record => (
                        <Button variant={"outlined"} color="primary"
                                href={'/party/' + record.id + '/4'}
                                label={"Контрагент"}
                        />
                    )}/>

                </DatagridWithFooter>

            </List>
        </>
    )
})

const ReportButton = (props) => {
    const {period, partyId, buttonLabel, dialogTitle} = props;
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="lg">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <PartyStatMonthlyList partyId={partyId} period={period}/>

                </DialogContent>
                <DialogActions>
                    <Button label="Закрыть" onClick={() => setOpen(false)}>
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={open ? "contained" : "outlined"}
                color="primary"
                onClick={(e) => setOpen(true)}
            >
                {buttonLabel ? buttonLabel : "Отчет"}
            </Button>
        </>
    );
};
