import React from 'react';
import moment from "moment-timezone";
import {
    BooleanInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NumberField,
    SelectInput,
    TextField,
    useListFilterContext,
} from 'react-admin';
import BaseListActions from "../components/BaseListActions";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


const idpMap = {
        nbki: {id: 71, name: "НБКИ", choices: [{id: 'CRV', name: 'CRV'}, {id: "FICO", name: 'FICO'}]},
        okb: {
            id: 134,
            name: "ОКБ",
            choices: [{id: 'IDV', name: 'IDV'},
                {id: "OKBQIWI", name: 'ОКБ-Qiwi'},
                {id: "UCPP", name: "UCPP"}]
        },
        rscb: {id: 143, name: "КБ Русский стандарт", choices: [{id: 'VERIFY', name: 'Верификация'}]},
        esia: {id: 226, name: "ЕСИА", choices: [{id: 'VERIFY', name: 'Проверка в ЕСИА'}]},
        parserApi: {
            id: 420,
            name: "Парсер API",
            choices: [
                {id: "140", name: 'ГИБДД. ВУ'},
                {id: "GIBDD_AUTO", name: "ГИБДД. Авто"},
                {id: "1200", name: "ГИБДД. Штрафы"},
                {id: "3400", name: "РСА. Осаго"},
                {id: "BANKROT", name: "Реестр банкротов"},
            ]
        },
        dbrain: {id: 295, name: "Dbrain", choices: []},
        osnovanie: {id: 39655, name: "Основание", choices: []},
        smev: {
            id: 311,
            name: "СМЭВ IDX",
            choices: [
                {id: 'SMEV_MVD', name: 'МВД'},
                {id: '1300', name: 'ФНС'},
                {id: '1340', name: 'ЕСИА'},
                {id: '1330', name: 'ПФР'}
            ]
        },
        sinara: {id: 50216, name: "Синара", choices: []},
        fns: {
            id: 312, name: "ФНС", choices: [
                {id: "110", name: "ИНН"},
                {id: "4710", name: "ИНН(fast)"}
            ]
        },
        ids: {
            id: 12715, name: "ID-Solutions", choices: [
                {id: "4710", name: 'ИНН'},
                {id: "4720, 4740", name: "ФССП"},
                {id: "5000", name: "Блокировка счетов"}
            ]
        },
        idx: {id: 116, name: "IDX", choices: [
                {id: "150", name: "ФМС"},
                {id: "110, 4710", name: "ИНН"},
                {id: "120", name: "ФССП"}
            ]},
        "idx-data": { id: 57111, name: "IDX-Data", choices:[
                {id: "180", name: "ИНН"},
                {id: "81, 1312", name: "Паспорт"}

            ]
        },

        reputation: {id:441, name: "Репутатция", choices:[
                {id: "3700", name:"Аффилированность"},
                {id: "5900", name: "legalEntityComplexCheck"},
                {id: "4500", name: "checkPep"}
            ]},
        basis: {id:14829, name: "Базис", choices:[]},
        solaris: {id:165, name: "Солярис", choices:[]},
    }
;

const OperationLogStatFilter = (props) => {
    const {idp} = props;
    //console.log("filter props", props);
    return (

        <Filter {...props} >
            <DateInput alwaysOn source="moment_gte" label="Дата c:" style={{width: "140px"}}/>
            <DateInput alwaysOn source="moment_lte" label="Дата по:" style={{width: "140px"}}/>

            <SelectInput label="Сервис" source="pseudoOperationType"
                         choices={idp.choices}
                         style={{width: "200px", marginBottom:0}}
                         alwaysOn translateChoice={false}
                         InputLabelProps={{shrink: true}}

            />
            <BooleanInput label="По датам" source="groupByDate" alwaysOn/>
            <BooleanInput label="По операциям" source="groupByOperationType" alwaysOn/>
        </Filter>
    )
}

const MyDatagrid = props => {
    const {filterValues} = useListFilterContext(props);
    const showDate = filterValues.groupByDate;
    const showOperationType = filterValues.groupByOperationType;
    const isOkb = filterValues['party.id'] && filterValues['party.id'] === idpMap.okb.id;
    return (
        <Datagrid bulkActionButtons={false}>
            {showDate && <DateField source="moment" label="Дата"/>}
            {showOperationType && <TextField source="operationType.name" label="Операция"/>}
            <NumberField source="total" label="Всего"/>
            <NumberField source="success" label="Успешно"/>
            <NumberField source="error" label="Ошибок"/>
            <NumberField source="notFound" label="Не найдeно"/>
            {isOkb && <NumberField source="okbHitPassport" label="ОКБ Hit Паспорт"/>}
            {isOkb && <NumberField source="okbHitPhone" label="ОКБ Hit Телефон"/>}
        </Datagrid>
    )
}


export const OperationLogStatList = props => {
    let d = new Date()
    const startDate = (d.getDate() === 1) ?
        moment(d).subtract(1, 'months').startOf('month').format("YYYY-MM-DD") :
        moment(d).startOf('month').format("YYYY-MM-DD");
    const stopDate = moment(d).format("YYYY-MM-DD");

    const idp = idpMap[props.idp];

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Сводная статистика по запросам в {idp.name}</Typography>

                <Typography variant="body2">
                    <br/>
                    "Дата с.. по" - выбор периода<br/>
                    "Сервис" - фильтр по сервисам {idp.name} <br/>
                    "По датам" - если отключено, то результаты отображается без разбивки по датам<br/>
                    "По операциям" - если отключено, то результаты отображаются без разбивки по операциям<br/>
                    <br/>
                </Typography>

                <List {...props}
                      hasShow={false} hasList={true} hasEdit={false} hasCreate={false}
                      title={"Статистика по " + idp.name}

                      filters={<OperationLogStatFilter idp={idp}/>}
                      actions={<BaseListActions name={"operationLogStat"}
                                                exportFilename={"operationLogStat-" + idp.name + ".xlsx"}/>}
                      perPage={50}
                      sort={{field: 'moment', order: 'DESC'}}
                      filterDefaultValues={{
                          moment_gte: startDate,
                          moment_lte: stopDate,
                          groupByDate: true,
                          groupByOperationType: true,
                          "party.id": idp.id
                      }}
                >
                    <MyDatagrid/>
                </List>

            </CardContent>
        </Card>
    )
}

export const OperationLogStatListNbki = props => {
    return (<OperationLogStatList {...props} idp="nbki"/>)
}
export const OperationLogStatListOkb = props => {
    return (<OperationLogStatList {...props} idp="okb"/>)
}
export const OperationLogStatListRscb = props => {
    return (<OperationLogStatList {...props} idp="rscb"/>)
}
export const OperationLogStatListEsia = props => {
    return (<OperationLogStatList {...props} idp="esia"/>)
}
export const OperationLogStatListParserAPi = props => {
    return (<OperationLogStatList {...props} idp="parserApi"/>)
}
export const OperationLogStatListDbrain = props => {
    return (<OperationLogStatList {...props} idp="dbrain"/>)
}
export const OperationLogStatListOsnovanie = props => {
    return (<OperationLogStatList {...props} idp="osnovanie"/>)
}
export const OperationLogStatListSmev = props => {
    return (<OperationLogStatList {...props} idp="smev"/>)
}
export const OperationLogStatListSinara = props => {
    return (<OperationLogStatList {...props} idp="sinara"/>)
}
export const OperationLogStatListFns = props => {
    return (<OperationLogStatList {...props} idp="fns"/>)
}
export const OperationLogStatListIds = props => {
    return (<OperationLogStatList {...props} idp="ids"/>)
}
export const OperationLogStatListIdx = props => {
    return (<OperationLogStatList {...props} idp="idx"/>)
}
export const OperationLogStatListIdxData = props => {
    return (<OperationLogStatList {...props} idp="idx-data"/>)
}



export const OperationLogStatListReputation = props => {
    return (<OperationLogStatList {...props} idp="reputation"/>)
}

export const OperationLogStatListBasis = props => {
    return (<OperationLogStatList {...props} idp="basis"/>)
}

export const OperationLogStatListSolaris = props => {
    return (<OperationLogStatList {...props} idp="solaris"/>)
}


//export default OperationLogStatList;
