import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import {withStyles} from '@mui/styles';
import {connect} from 'react-redux';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import dataProvider from 'components/data-provider';
import {GET_MANY_REFERENCE} from 'react-admin';

const renderInput = (inputProps)=> {
  const { classes, ...other } = inputProps;

  return (
    <TextField
      name="auto-complete"
      fullWidth
      margin="dense"
      label={ inputProps.label ? inputProps.label :  "Supplier" }
      variant="outlined"
      placeholder= 'Введите минимум 3 символа'
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
      }}
      { ...other }
    />
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    p_height: 250,
    width: 250,
    marginBottom: '4px',
    marginRight: '20px'
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 0,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  innLabel1: {
    margin: 0,
    fontSize: 13,
  },
  innLabel2: {
    marginTop: 0,
    fontSize: 11
  }
});

class AutocompleteDownshift extends Component {
  state = { value: "", suggestions: [] }

  constructor(props) {
    super(props);

    if (this.props.myRefs) {
      this.props.myRefs['autoComplete'] = this;
    }
  }

  getSuggestions = event => {
    const self = this;
    const val = event.target.value;
    this.setState({...this.state, value: val})

    if (self.props.onChangeValue) {
      self.props.onChangeValue(event);
    }

    if (val && val.length >= 3) {
      dataProvider(GET_MANY_REFERENCE, 'party', {
        target: 'name',
        id: val,
        pagination: { page: 1 , perPage: 5 },
        sort: { field: 'name', order: 'ASC' }
      })
      .then(response => {
        self.setState({ suggestions: response && response.data });
      });
    } else {
      self.setState({ suggestions: [] });
    }

    return true;
  }

  renderSuggestion({ highlightedIndex, getItemProps, classes, selectedItem }) {
    return (
      this.state.suggestions.map((suggestion, index) =>
        <MenuItem
            {...getItemProps({ item: suggestion })}
            key={ `suggestion-${suggestion.id}` }
            value={ index }
            selected={ highlightedIndex === index }
            style={{
              //fontWeight: ((selectedItem ? selectedItem.key : '').indexOf(suggestion.id) >= 0) ? 500 : 400,
            }}
            component="div"
        >
          <div>
            <div className={classes.label2}> { suggestion.name } </div>
          </div>
        </MenuItem>
      )
     );
  }

  render() {
    const { classes } = this.props;

    //console.log("autocomplete: ", this.props);

    return (
      <div className={ classes.root }>
        <Downshift id="autoSelect" styles={{ marginBottom: '4px' }}
          onChange={ selection =>  { 
            //console.log('selection',selection)
            this.setState({...this.state, value: selection.name })
            this.props.onSelect(selection)
          }}
          itemToString={ item => (item ? item.name : '') }
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          }) => (
            <div className={ classes.container }>
              { renderInput({
                fullWidth: true,
                label: this.props.label,
                classes,
                onKeyPress: this.props.onKeyPress ? this.props.onKeyPress : null,
                InputProps: getInputProps({
                  onChange: this.getSuggestions, 
                  value: this.state.value
                }),
              })}
              <div { ...getMenuProps() }> 
                { isOpen ? (
                  <Paper className={ classes.paper } square> 
                    { this.renderSuggestion({
                        highlightedIndex,
                        getItemProps,
                        classes,
                        selectedItem
                    })}
                   </Paper>
                ) : null }
              </div>
            </div>
          )}
        </Downshift>
      </div>
    )
  }
}

AutocompleteDownshift.propTypes = {
  classes: PropTypes.object.isRequired,
  //refs: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => {
  return {
    partyValue: state.partyAutocomplete ?  (state.partyAutocomplete.payload ? state.partyAutocomplete.payload : "") : ""
  };
}


export default connect(mapStateToProps, null)(withStyles(styles)(AutocompleteDownshift));
