import React, {useState} from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import dataProvider from '../components/data-provider';
import MyEditableLabel from "../components/MyEditableLabel";
import {
    useGetList,
    useNotify,
    ListContextProvider,
    Pagination,
    usePermissions,
    useRecordContext,
    useDelete, SortButton, useListSortContext
} from "react-admin";
import {DEFAULT_PARTY_ID} from "../constants/const.js";
import Card from "@mui/material/Card";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';

import Typography from "@mui/material/Typography";
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import roles from "../constants/roles";
import PlaylistAddcon from "@mui/icons-material/PlaylistAdd";
import NoteAddcon from "@mui/icons-material/NoteAdd";
import IdxDialog from "../components/dialog";
import TariffCreate, {TariffCreateEx} from "./TariffCreate";
import {withTransaction} from "@elastic/apm-rum-react";
import PropTypes from "prop-types";
import CustomSortButton from "../components/CustomSortButton";
import {formatMoney} from "../lib/utils";

const styles = theme => ({
    container: {minWidth: '600px', marginLeft: '1em'},
    rightAlignedCell: {textAlign: 'right'},
    boldCell: {fontWeight: 'bold'},
    tightCell: {width: '70px'},
    centerCell: {textAlign: 'center'},
    head: {
        backgroundColor: "#ededed",
        color: theme.palette.common.white,
        '& th': {
            padding: '4px',
        }
    },
    row: {
        // '&:nth-of-type(even)': {
        //     backgroundColor: theme.palette.background.default,
        // },
        '& td': {
            padding: '0px 4px 0px 4px',
        },

    },

    button: {
        marginTop: 7,
        marginLeft: 14,
        marginBottom: 10
    }
});


const BaseTariffList = (props) => {
    const {partyId, classes, allowEdit} = props;
    const notify = useNotify();

    const [isOpenDialog, setOpenDialog] = useState(false);
    const [isOpenDialogEx, setOpenDialogEx] = useState(false);

    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(1);

    const [sort, setSort] = useState({field: 'operationType.name', order: 'ASC'});

    const perPage = 100;

    const {data, total, isLoading} = useGetList('tariff', {
        filter: {partyId: partyId},
        pagination: {page, perPage},
        sort: sort,
    });

    const getCellCss = (data, num) => {
        return {
            "borderTop": ((num > 0 && data[num - 1].operationType.id === data[num].operationType.id) ? 0 : 1),
            "borderBottom": 0
        }
    }

    const handleEdit = (ref, num, typ, val) => {
        //сначала проверяется правильность ввода (цифры) и сравнение со старым значением
        // let oldVal = typ === "price" ? data[num].price / 100 : (typ === "fromQty" ? data[num].fromQty : data[num].toQty);
        let oldVal = typ === "price" ? data[num].price / 100 : data[num][typ];

        console.log("val", val, "oldVal", oldVal);

        if (isNaN(val) || (val == '' && typ !== 'toQty')) {
            ref.setState({text: (oldVal == null ? '' : oldVal)});
            return;
        }
        if (oldVal == val) return;
        // console.log("oldVal=", oldVal, "val=", val, "===", oldVal===val, "==", oldVal==val);
        if (oldVal == null && val == 0) {
            ref.setState({text: (oldVal)})
            return;
        }

        let params={};
        params[typ]=val;

        console.log("params", params);
        // потом отправка на сервер
        dataProvider
            .update('tariff', {
                id: data[num].id,
                data: params
        // {
        //             paramName: typ,
        //             value: val
        //         }
            })
            .then(response => {
                data[num][typ] = val;
                notify('Значение успешно изменено', {autoHideDuration: 4000});
            })
            .catch((e) => {
                ref.setState({text: oldVal});
                console.error(e);
                notify(`Ошибка при редактировании: ${e}`, {type: 'error'});
            });
    }

    const handleCloseDialog = (isNeedRefresh) => {
        setOpenDialog(false);
        if (isNeedRefresh) {

        }
    }

    const TariffDeleteButton = (props) => {
        const record = useRecordContext();
        const [deleteOne, {isLoading, error}] = useDelete();
        const handleClick = () => {
            deleteOne('tariff', {id: props.id});
        }
        return (<Button {...props}
                        label="Удалить" resource="tariff"
                        disabled={isLoading || props.disabled}
                        onClick={() => handleClick(record.id)}
                        color="error"
        ><DeleteIcon/></Button>)
    }


    // const filters = [<TextField label="Search" source="q" size="small" alwaysOn/>];
    const filterValues = {q: filter};
    const setFilters = filters => setFilter(filters.q);

    if (isLoading) return "Loading...";

    return (
        <>
            <span>
            <Button size="small" variant="contained" color="primary" className={classes.button}
                    onClick={() => setOpenDialog(true)}
                    disabled={!allowEdit}
            >Добавить запись<NoteAddcon className={classes.rightIcon}/>
            </Button>

                <IdxDialog title="Добавить тариф"
                           width={500}
                           maxHeight={1400}
                           isOpen={isOpenDialog}
                           mixins={true}
                           onClose={() => handleCloseDialog()}
                           content={
                               <TariffCreate id={partyId} onSuccess={() => handleCloseDialog(true)}/>
                           }
                />
            </span>

            {/*расширенное добавление*/}
            <span>
            <Button size="small" variant="contained" color="primary" className={classes.button}
                    onClick={() => setOpenDialogEx(true)}
                    disabled={!allowEdit}
            >Добавить тариф
                <PlaylistAddcon className={classes.rightIcon}/>
            </Button>

                <IdxDialog title="Добавить тариф"
                           width={600}
                           maxHeight={1400}
                           isOpen={isOpenDialogEx}
                           mixins={true}
                           onClose={() => setOpenDialogEx(false)}
                           content={
                               <TariffCreateEx id={partyId} onSuccess={() => setOpenDialogEx(false)}/>
                           }
                />
            </span>

            <Card className={classes.container}>
                <ListContextProvider value={{
                    data: data || [],
                    total: total || 0,
                    page,
                    perPage,
                    setPage,
                    filterValues,
                    setFilters,
                    sort,
                    setSort
                }}>
                    <Table sx={{backgroundColor: allowEdit ? "" : "#f5f5f5"}}>
                        <TableHead>
                            <TableRow className={classes.head}>
                                <TableCell style={{maxWidth: '100px', textAlign: 'center'}}>ID
                                    <CustomSortButton sort={sort} setSort={setSort} field="operationType.id"/>
                                </TableCell>
                                <TableCell>Операция
                                    <CustomSortButton sort={sort} setSort={setSort} field="operationType.name"/>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell className={classes.rightAlignedCell}>От</TableCell>
                                <TableCell className={classes.rightAlignedCell}>До</TableCell>
                                <TableCell className={classes.rightAlignedCell}>Цена, руб</TableCell>
                                <TableCell></TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data && data.length > 0
                                ?
                                data.map(
                                    (item, num) =>
                                        <TableRow className={classes.row} key={item.id}>
                                            <TableCell sx={getCellCss(data, num)}>
                                                {(num > 0 && data[num - 1].operationType.id === item.operationType.id) ? "" : item.operationType.id}
                                            </TableCell>

                                            <TableCell sx={getCellCss(data, num)}>
                                                {(num > 0 && data[num - 1].operationType.id === item.operationType.id) ? "" : item.operationType.name}
                                            </TableCell>

                                            <TableCell sx={getCellCss(data, num)}>
                                                {(num > 0 && data[num - 1].operationType.id === item.operationType.id) ? "" : item.operationType.displayName}
                                            </TableCell>


                                            <TableCell className={classes.rightAlignedCell} sx={getCellCss(data, num)}>
                                                {allowEdit ?
                                                    <MyEditableLabel
                                                        text={"" + item.fromQty.toLocaleString()}
                                                        inputWidth='100px'
                                                        inputHeight='20px'
                                                        inputMaxLength={10}
                                                        onFocus={(ref, val) => {
                                                            if (val === '-') ref.state.text = '0';
                                                        }}
                                                        onFocusOut={(ref, val) => {
                                                            handleEdit(ref, num, 'fromQty', val)
                                                        }}
                                                    />
                                                    :
                                                    (item.fromQty == null ? 0 : item.fromQty.toLocaleString())
                                                }
                                            </TableCell>

                                            <TableCell className={classes.rightAlignedCell} sx={getCellCss(data, num)}>
                                                {allowEdit ?
                                                    <MyEditableLabel
                                                        text={"" + (item.toQty == null ? "" : item.toQty.toLocaleString())}
                                                        inputWidth='100px'
                                                        inputHeight='20px'
                                                        inputMaxLength={10}
                                                        onFocus={(ref, val) => {
                                                            if (val === '') ref.state.text = '0';
                                                        }}
                                                        onFocusOut={(ref, val) => {
                                                            handleEdit(ref, num, 'toQty', val)
                                                        }}
                                                    />
                                                    :
                                                    (item.toQty == null ? "" : item.toQty.toLocaleString())
                                                }

                                            </TableCell>
                                            <TableCell className={classes.rightAlignedCell} sx={getCellCss(data, num)}>
                                                {allowEdit ?
                                                    <MyEditableLabel
                                                        text={formatMoney(item.price / 100.0)}
                                                        inputWidth='100px'
                                                        inputHeight='20px'
                                                        inputMaxLength={6}
                                                        onFocus={(ref, val) => {
                                                            if (val === '-') ref.state.text = '0';
                                                        }}
                                                        onFocusOut={(ref, val) => {
                                                            handleEdit(ref, num, 'price', val)
                                                        }}
                                                    />
                                                    :
                                                    (item.price == null ? 0 : (item.price / 100.0).toLocaleString())
                                                }
                                            </TableCell>
                                            <TableCell className={classes.rightAlignedCell} sx={getCellCss(data, num)}>
                                                <TariffDeleteButton id={item.id} disabled={!allowEdit}/>
                                            </TableCell>


                                        </TableRow>
                                )
                                :
                                <TableRow key={'no-data'}>
                                    <TableCell>Специальные тарифы не настроены для контрагента</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>

                    <Pagination/>
                </ListContextProvider>


            </Card>
        </>
    );
}


const TariffList = (props) => {
    const {partyId} = props;
    const {permissions} = usePermissions();

    const MyAccordionSummary = styled((props) => (
        <AccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon/>}
            {...props}
        />
    ))(({theme}) => ({
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(2),
        },
    }));

    return (
        <div>
            {partyId != DEFAULT_PARTY_ID &&

                <Accordion disableGutters defaultExpanded>
                    <MyAccordionSummary
                        id="panel1-header"
                        aria-controls="panel1-content">
                        <Typography variant="h6">Специальные тарифы контрагента</Typography>
                    </MyAccordionSummary>
                    <AccordionDetails>
                        <BaseTariffList {...props} partyId={partyId} title="Специальные тарифы контрагента"
                                        allowEdit={permissions.includes(roles.party_admin)}
                        />
                    </AccordionDetails>
                </Accordion>
            }

            <Accordion disableGutters>
                <MyAccordionSummary
                    id="panel2-header"
                    aria-controls="panel2-content">
                    <Typography variant="h6">Стандартные тарифы</Typography>
                </MyAccordionSummary>
                <AccordionDetails>
                    <BaseTariffList {...props} partyId={DEFAULT_PARTY_ID} title="Стандартные тарифы"
                                    allowEdit={permissions.includes(roles.party_admin) && partyId == DEFAULT_PARTY_ID}/>
                </AccordionDetails>
            </Accordion>

        </div>
    )
}


/*
const CustomSortButton = (props) => {
    const inverseOrder = (sort) => (sort === 'ASC' ? 'DESC' : 'ASC');
    const {field, sort, setSort} = props;
    const handleChangeSort = (event) => {
        if (field) {
            setSort({
                field,
                order: field === sort.field ? inverseOrder(sort.order) : 'ASC'
            });
        }
    };
    return (
        <Button
            onClick={handleChangeSort}
            startIcon={field == sort.field ? (sort.order == 'ASC' ? <ArrowUpIcon/>:<ArrowDownIcon/>): <SortIcon/>}
            endIcon={false}
            size="small"
        />
    );
};
CustomSortButton.propTypes={
    field: PropTypes.string.isRequired,
    sort: PropTypes.object.isRequired,
    setSort: PropTypes.func.isRequired
}
*/


export default withStyles(styles)(withTransaction('TariffList', 'component')(TariffList));