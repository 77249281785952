import React, {PureComponent} from 'react';
import {withStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import red from '@mui/material/colors/red';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  root: {
    // ...theme.mixins.gutters(),
  },
  dialog: {
    maxHeight: 400
  },
  card: {
    margin: '0 auto'
  },
  cardHeader: {
    backgroundColor: '#bbdefb'
  },
  avatar: {
    backgroundColor: red[500],
    marginRight: "10px"
  },
  toolbar: {
    position: 'relative',
    backgroundColor: '#bbdefb'
  },
  closeBtn: {
    marginLeft: 'auto', 
    marginRight: '-20px',
  },
  downloadBtn: {
    marginLeft: '20px', 
    textTransform: 'none'
  },
});

class IdxDialog extends PureComponent {
  state = { isClose: false }

  componentDidMount() {
  }

  handleCloseBtn() {
    this.props.onClose();
  }

  render() {
    const { classes, title, content, isOpen, width, maxHeight } = this.props;

    //const width = 400;

    return (
      <Dialog open={ isOpen } className={ classes.dialog } style={{ maxHeight }}>
        <div>
          <Card className={ classes.card } style={{ width }}>
            <Toolbar className={ classes.toolbar }>
              {/* <Avatar aria-label="Recipe" className={ classes.avatar }> Idx </Avatar> */}
              <Typography variant="caption">{ title }</Typography>
              <IconButton color="inherit" onClick={() => this.handleCloseBtn()} aria-label="Close" className={ classes.closeBtn }>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <CardContent>  
              { content }
            </CardContent>
          </Card>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(IdxDialog);
