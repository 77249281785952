import React from 'react';
import {Edit, ListButton, SaveButton, SimpleForm, TextField, TextInput, Toolbar} from 'react-admin';

const OperationTypeTitle = ({ record }) => {
    return (<span>Тип операции {record ? `"${record.name}"` : ''}</span>);
};
//
// const useStyles = makeStyles({
//     toolbar: {
//         display: 'flex',
//         justifyContent: 'space-between',
//     },
// });

const CustomToolbar = props => {
    const {basePath, classes} = props;
    return (
    <Toolbar {...props} >
        <SaveButton submitOnEnter={false} undoable={false} />
        <ListButton basePath={basePath} label="Отмена"  style={{marginLeft: "30px"}}/>
        {/*<DeleteButton resource="operationType" submitOnEnter={false} />*/}
    </Toolbar>
    )
};

const OperationTypeEdit = props => (
    <Edit title={<OperationTypeTitle />}  {...props}  >
        <SimpleForm  submitOnEnter={false}  redirect="list" toolbar={<CustomToolbar/>}>
            <TextField  source="id" disabled/>
            <TextInput source="name" label="Операция" style={{minWidth:"400px"}}/>
            <TextInput multiline source="comment" label="Источник" style={{minWidth:"400px"}}/>
            <TextInput multiline source="displayName" label="Описание" fullWidth/>
        </SimpleForm>
    </Edit>
);

export default OperationTypeEdit;