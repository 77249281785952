import React from 'react';
import {BooleanInput, Edit, NumberInput, SimpleForm, TextField, TextInput} from 'react-admin';

const FaqTitle = ({ record }) => {
    return <span>Часто задаваемые вопросы: вопрос {record ? `"${record.id}"` : ''}</span>;
};

const FaqEdit = props => (
    <Edit title={<FaqTitle />} {...props} >
        <SimpleForm  submitOnEnter={false}  redirect="list">
            <TextField  source="id" />
            <TextInput multiline row={3}  fullWidth source="question" label="Вопрос"/>
            <TextInput multiline row={3} fullWidth source="answer" label={"Ответ"} />
            <BooleanInput source="visible" label={"Активен (не отключен)"}/>
            <NumberInput source="orderNum" label={"Порядковый номер"}/>
        </SimpleForm>
    </Edit>
);

export default FaqEdit;