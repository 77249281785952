import React from 'react';
import {Layout, Sidebar} from 'react-admin';
import AppBar from './AppBar';
import MyMenu from './IdxMenu';


const CustomSidebar = props => <Sidebar  {...props}  />;
export const IdxLayout = props => (
    <Layout {...props} appBar={ AppBar } sidebar={ CustomSidebar } menu={ MyMenu } >
    </Layout>
);

